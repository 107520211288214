import React, { Component } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import { browserHistory, Link } from 'react-router';
import { userLogin } from 'store/user';
import Modal from 'components/Modal';
import InputValidation from 'components/InputValidation';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      smscode: '',
      showsms: false,
      errorModalMessage: '',
      showPassword: false,
      errors: {},
      xyz: false
    };
  }

  socketLogin = ({ type, data, message, banned, smscode }) => {

    if (this.SignInRef) {
      if (type === 'smsOk') {
        this.setState({
          showsms: true,
          errorModalMessage: message,
        });
      } else if (type === 'setOk') {
        this.props.login(data);
        browserHistory.push('/');
      } else if (type === 'smsErr') {
        this.setState({
          errorModalMessage: message,
        });
      } else if (['setErr', 'facebookErr', 'googleErr'].indexOf(type) > -1) {
        const bannedMessage = 'Dear Customer,\n' +
          'Thank you for your interest. We are really sorry but it seems that your profile cannot be activated. ' +
          'Please contact our team on team@loseweight.ae or 02-444-2595 for further clarifications.\n' +
          'Regards,\n' +
          'Lose Weight Team';
        this.setState({ errorModalMessage: banned ? bannedMessage : message });
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('login', this.socketLogin);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('login', this.socketLogin);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  submit(e, browserId) {
    e.preventDefault();
    const { email, password, smscode } = this.state;
	console.log('Login Trigger');
    this.props.socket.emit('login', { type: 'set', data: { email, password, browserId, app: 'customer', smscode: smscode } });
  }

  submitFacebook(response, browserId) {
    if ('id' in response) {
      this.props.socket.emit('login', { type: 'facebook', data: { ...response, browserId }, app: 'customer' });
    }
  }

  submitGoogle(response, browserId) {
    this.props.socket.emit('login', { type: 'google', data: { ...response, browserId }, app: 'customer' });
  }

  render() {
    const onChange = (name, value) => this.setState({ [name]: value });
    const { errors, errorModalMessage, email, password, xyz, showsms, smscode } = this.state;
    const { settings, browserId } = this.props;
    // const facebookAppId = settings.getValue('FACEBOOK_APP_ID');
    // const googleAppId = settings.getValue('GOOGLE_APP_ID');
    const facebookAppId = '';
    const googleAppId = '';
	//console.log(browserId);
    return (
      <div ref={el => (this.SignInRef = el)} className={`row main-holder flex-column justify-content-center main-on-mb align-items-center ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='b-rounded-blue sign-up-form text-center shadowed'>
          <div className='search-title mt-1 sign-heading'>
            <span className='colorTitle'>Sign In</span>
          </div>
          <form className='form' onSubmit={e => this.submit(e, browserId)}>
            {!showsms && <div className='sign-up-input mt-4'>
              <InputValidation
                value={email}
                name='email'
                onChange={onChange}
                placeholder='E-mail address'
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>}
             {!showsms && <div className='sign-up-input mt-4'>
              <InputValidation
                type='password'
                name='password'
                value={password}
                onChange={onChange}
                placeholder='Password'
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>}
            {showsms && <div className='sign-up-input mt-4'>
              <InputValidation
                type='smscode'
                name='smscode'
                value={smscode}
                onChange={onChange}
                placeholder='Sms Code'
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>}
            <div className='search-title login-text mt-3'>
            <p className='line-height-xsnormal letter-xs login-text mb-0'>
              By signing in or signing up, I agree to Lose Weight &nbsp;
              <Link to='/terms' className='letter-xs'>Terms & Conditions</Link>, <Link to='/refunds' className='letter-xs'>Refund & Cancellation policy</Link>, <Link to='/privacy-policy' className='letter-xs'>Privacy Policy</Link>,
              I confirm that I am 18 years of age or older, and consent to receiving email communication.
            </p>
            </div>
            <div className='sign-up-input mt-4 sign-up-button d-flex align-items-center justify-content-around'>
              <input type='submit' value='Login' className='letter-sm w-100' />
            </div>
          </form>
          {/*<div className='search-title login-text mt-3'>
            <span className='colorTitle letter-sm'>Or</span>
    </div>*/}
          {googleAppId && <GoogleLogin
            clientId={googleAppId}
            onSuccess={response => this.submitGoogle(response, browserId)}
            buttonText='Sign in with Google'
            className='sign-up-input mt-3 sign-up-button-google d-flex align-items-center justify-content-around'
            onFailure={data => console.log(data)}
          />}
          {facebookAppId && <FacebookLogin
            appId={facebookAppId}
            size='small'
            callback={response => this.submitFacebook(response, browserId)}
            fields='name,email,picture,first_name,last_name'
            textButton='Sign in with Facebook'
            cssClass='sign-up-input mt-3 mb-2 sign-up-button-facebook d-flex align-items-center justify-content-around'
          />}
          <div className='login-text mt-3'>
            <span><Link to='/restore'>Forgot password?</Link></span>
          </div>
          {/*<span className='mt-1 mr-1'>Don't have a Mealplan account?</span>
          <span className='login-text'><Link to='/sign-up'>Sign up now.</Link></span>*/}

        </div>
        {errorModalMessage &&
          <Modal
            close={() => this.setState({ errorModalMessage: '' })}
            message={errorModalMessage}
          />
        }
      </div>
    );
  }
}

SignIn.propTypes = {
  socket: PropTypes.object.isRequired,
  settings: PropTypes.object,
  browserId: PropTypes.string,
  login: PropTypes.func.isRequired
};

const props = state => ({
  browserId: state.user.browserId,
  language: state.user.language,
  settings: state.settings
});

const actions = dispatch => ({
  login: user => dispatch(userLogin(user))
});

export default socketConnect(connect(props, actions)(SignIn));
