import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import InputValidation from 'components/InputValidation';
import { browserHistory } from 'react-router';

class Restore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      showModal: false,
      modalMessage: '',
      xyz: false
    };
  }

  listener = action => {
    if (this.RestoreRef) {
      if (action.type === 'tryOk') {
        this.setState({
          showModal: true,
          modalMessage: (
            <div>
              <h3>Email sent!</h3>
              <p>{action.message}</p>
              <p> In case of any difficulties please reach us on <a href="tel:+971502453860" class="tel">+971 50 245 3860</a></p>
            </div>
          )
        });
      } else if (action.type === 'tryErr') {
        console.log('restore error');
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('restore_password', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('restore_password', this.listener);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 10);
  }

  submit(e) {
    e.preventDefault();
    const data = {
      email: this.state.email
    };
    this.props.socket.emit('restore_password', { type: 'try', data: data });
  }

  render() {
    const { errors, showModal, modalMessage, xyz } = this.state;
    const onChange = (name, value) => this.setState({ [name]: value });
    return (
      <div ref={el => (this.RestoreRef = el)} className={`main-holder d-flex flex-column justify-content-center align-items-center ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='b-rounded-blue sign-up-form text-center shadowed restore-form'>
          <div className='search-title mt-1'>
            <span className='colorTitle'>Restore</span>
          </div>
          <form className='form' onSubmit={e => this.submit(e)}> 
            <div className='sign-up-input mt-4'>
              <InputValidation
                name='email'
                value={this.state.email}
                onChange={onChange}
                placeholder={'E-mail address'}
                className={`form-input ${'email' in errors ? 'has-error' : ''}`}
                errors={errors}
                inputSelfClassName='standart-input b-rounded-blue'
              />
            </div>
            <div className='sign-up-input my-4 sign-up-button d-flex align-items-center justify-content-around'>
              <input type='submit' value='Restore' className='letter-sm w-100' />
            </div>
          </form>
        </div>
        {showModal &&
          <Modal
            close={() => {
              this.setState({ showModal: false });
              browserHistory.push('/sign-in');
            }}
            message={modalMessage}
            headLine="Password Reset"
          />
        }
      </div>
    );
  }
}

Restore.propTypes = {
  socket: PropTypes.object.isRequired
};

export default socketConnect(Restore);