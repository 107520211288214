import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { setModal, clearModal } from '../../store/modal';
import { clearPlanParams, setPlanParams } from 'store/planParams';
import { clearMealPlan } from 'store/mealPlan';
import PaperButton from 'react-paper-button';
import { socketConnect } from 'socket.io-react';
import InputValidation from 'components/InputValidation';

class HomeView extends Component {
  constructor(props) {
    super(props);
    const { user, dataArray } = this.props;
    this.state = {
      xyz: false,
      showModal: false,
      'user': user,
      notes: null,
      cname: null,
      cphone: null,
      cemail: null,
      errors:{},
      'dataArray': dataArray,
    };
  }

  listener = action => {
      switch (action.type) {
        case 'planRequestOk':
          const {clearModal, setModal} = this.props;
          clearModal();
          setModal({ headLine: 'Request Sent', message: (
            <div>
              <p>Thank you! Our team will be in touch with you.</p>
            </div>)
          });
                                        
        break;
      }
    };  
  
  setNotification(changes, thetype) {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setNotification', 
          'userId': user.user_id,
          'changes': changes,
          'thetype': thetype,
        } 
    });
  }

  changeNotesInfo(name, value) {

    this.setState({
      [name]:value,
    });

    setTimeout(() => this.updateMealplanPopup(false), 10);

  }

  updateMealplanPopup(first) {
    const {setModal, clearModal} = this.props;
    const {notes} = this.state;
    if (first) {
      //this.setNotification('Renew mealplan Request', 2);
    }
    return (
            setModal({ headLine: 'Renew meal plan', message: (
                                      <div>
                                        <p>Its great to see that you would like to renew your meal plan and continue your wellness journey.
                                        Our team, will get back to you soon with updated menu. <br/><br/>Please leave us a note, if any.</p>

                                        <textarea className="medical-text-area" value={notes} onChange={ (e) => { this.changeNotesInfo('notes', e.target.value)}}/>
                                        <p align="center"><PaperButton
                                          onClick={e => {
                                            const {user, notes} = this.state;
                                            var data = {
                                              'notes': notes, 
                                            };

                                            this.setNotification('Renew mealplan Request: '+notes, 2);
                                            clearModal();
                                            setModal({ headLine: 'Thank you', message: 'Request sent, will get back to you soon.'}); 
                                            this.props.socket.emit('profile', { type: 'getForDashboard', subtype: 'planRequest', data: data});
                                          }}
                                          className='button-regular next transition'
                                        >
                                          <span>Send Request</span>
                                        </PaperButton></p>
                                      </div>
            )})
    )
  }
  changeNewPlanInfo(name, value) {

    this.setState({
      [name]:value,
    });

    setTimeout(() => this.updateNewMealplanPopup(false), 10);

  };

  updateNewMealplanPopup(first) {
    const {setModal, clearModal} = this.props;
    const {cname,cphone,cemail,errors} = this.state;
    setModal({ headLine: 'No Active Meal Plan', message: (
                              <div>
                                <p>Send request to Lose Weight to add a new Mealplan</p>
                                <div className='sign-up-input mt-4'> <InputValidation
                                  value={cname}
                                  name='name'
                                  onChange={ (name,value) => { this.changeNewPlanInfo('cname', value)}}
                                  placeholder='Name'
                                  errors={errors}
                                  inputSelfClassName='standart-input b-rounded-blue'
                                /></div>
                                 <div className='sign-up-input mt-4'> <InputValidation
                                  value={cemail}
                                  name='email'
                                  onChange={ (name,value) => { this.changeNewPlanInfo('cemail', value)}}
                                  placeholder='Email'
                                  errors={errors}
                                  inputSelfClassName='standart-input b-rounded-blue'
                                /></div>
                                 <div className='sign-up-input mt-4'> <InputValidation
                                  value={cphone}
                                  name='phone'
                                  onChange={ (name,value) => { this.changeNewPlanInfo('cphone', value)}}
                                  placeholder='Phone'
                                  errors={errors}
                                  inputSelfClassName='standart-input b-rounded-blue'
                                /></div>
                                <p align="center"><PaperButton
                                  onClick={e => {
                                    // const {user,cname,cemail,cphone} = this.state;
                                    var data = {
                                      email: cemail, 
                                      name: cname, 
                                      'phone': cphone 
                                    };
                                    if(cemail!=''){
                                      this.props.socket.emit('profile', { type:'guestplanRequest', data: data});
                                      this.setNotification('New Meal Plan Request - '+"Name: "+cname+",Email:"+cemail+",Phone:"+cphone, 2);
                                    }
                                    clearModal();
                                    setModal({ headLine: 'Thank you', message: 'Request sent, will get back to you soon.'}); 
                                  }}
                                  className='button-regular next transition'
                                >
                                  <span>Send Request</span>
                                </PaperButton></p>
                              </div>
    )});
  };

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  componentDidMount() {
    this.props.clearPlanParams();
    this.props.clearMealPlan();
    this.HomeViewRef && setTimeout(() => this.setState({ xyz: true }), 300);
  }

  goToMenuBuilding = planType => {
    if (planType !== 'disabled') {
      const { setPlanParams, clearMealPlan } = this.props;
      setPlanParams({ step: 1, build: planType });
      clearMealPlan();
      browserHistory.push('/menu');
    }
    return null;
  };

  goToChangeAddress = (isLogin, hasPlan) => {
    if (isLogin && hasPlan) {
      browserHistory.push('/modify/address');
    }
    return null;
  };

  render() {
    const { xyz } = this.state;
    const { isLogin, setModal, hasPlan, renewPlan, canBeRenewed } = this.props;
    const setContactModal = () => setModal({
      message: (
        <div className='row'>
          <div className='col-12'>
            <p className='text-center'>
              <a href='tel:+971502453860' className='tel'>0502453860</a>
            </p>
            <p className='text-center spaced'>or Email us on</p>
            <p className='text-center'>
              <a href='mailto:team@healthyfresh.ae' className='mail'>team@healthyfresh.ae</a>
            </p>
          </div>
        </div>
      ), headLine: 'Get in touch!' });
  
    const planType = !hasPlan ? 'newPlan' : (renewPlan && canBeRenewed ? 'renew' : 'disabled');
    const buttProps = {
      /*newPlan: {
        title: 'Build my meal plan',
        className: '',
        onClick: () => this.goToMenuBuilding(planType)
      },*/
      newPlan: {
        title: 'Build my meal plan',
        className: '',
        onClick: () => {
          this.updateNewMealplanPopup(true)
           /* setModal({ headLine: 'No Active Meal Plan', message: (
                                      <div>
                                        <p>Send request to Lose Weight to add a new Mealplan</p>
                                        <p align="center"><PaperButton
                                          onClick={e => {
                                            const {user} = this.state;
                                            /*var data = {
                                              userId: user.user_id, 
                                              email: user.email, 
                                              firstName: user.firstName, 
                                              lastName: user.lastName, 
                                              'phone': user.phone 
                                            };
                                            //this.props.socket.emit('profile', { type: 'getForDashboard', subtype: 'planRequest', data: data});
                                            this.setNotification('New Meal Plan Request', 2);
                                            clearModal();
                                            setModal({ headLine: 'Thank you', message: 'Request sent, will get back to you soon.'}); 
                                          }}
                                          className='button-regular next transition'
                                        >
                                          <span>Send Request</span>
                                        </PaperButton></p>
                                      </div>
            )});*/
        }
      },
      renew: {
        title: 'Renew meal plan',
        className: '',
       // onClick: () => this.goToMenuBuilding(planType)
        onClick: () => this.updateMealplanPopup(true)
      },
      disabled: {
        title: 'Build my meal plan',
        className: 'disabled-before',
        onClick: () => {}
      }
    }[planType];
    return (
      <div ref={el => (this.HomeViewRef = el)} className={`row bg-1-1 main-page mealplan-heading-bx m-0 main-holder h-100 ver  ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='col-12 main-content without-scroll'>
          <div className='row title-holder'>
            <div className='col-12 title-block main-title'>
              <h1 class='clr-white'>Meal Plan</h1>
              <p>
                
                <span class='clr-white'>Healthy living</span>
                
              </p>
            </div>
          </div>
          <div className='row buttons-holder'>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-end align-items-center align-items-md-end'>
              <div onClick={buttProps.onClick} className={`transition button-regular main ${buttProps.className}`}>
                <span className='title'>{buttProps.title}</span>
              </div>
            </div>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-end align-items-center align-items-md-start'>
              <div onClick={setContactModal} className='transition button-regular main'>
                <span className='title'>Contact team</span>
              </div>
            </div>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-start align-items-center align-items-md-end'>
              <div onClick={() => isLogin && hasPlan && browserHistory.push('/modify')} className={`transition button-regular main ${isLogin && hasPlan ? '' : 'disabled-before'}`}>
                <span className='title'>View / Modify my menu</span>
              </div>
            </div>
            <div className='col-12 col-md-6 d-flex flex-column justify-content-start align-items-center align-items-md-start'>
              <div onClick={() => this.goToChangeAddress(isLogin, hasPlan)} className={`transition button-regular main ${isLogin && hasPlan ? '' : 'disabled-before'}`}>
                <span className='title'>Change delivery address</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeView.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  hasPlan: PropTypes.bool.isRequired,
  renewPlan: PropTypes.bool.isRequired,
  canBeRenewed: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  clearPlanParams: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  clearMealPlan: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { token, hasPlan, renewPlan, canBeRenewed } = state.user;
  return {
    dataArray: state.dataArray,
    planParams: state.planParams,
    user: state.user,
    isLogin: !!token,
    hasPlan,
    renewPlan,
    canBeRenewed
  };

};

const bindAction = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  clearModal: obj => dispatch(clearModal(obj)),
  clearPlanParams: () => dispatch(clearPlanParams()),
  setPlanParams: obj => dispatch(setPlanParams(obj)),
  clearMealPlan: () => dispatch(clearMealPlan())
});

export default socketConnect(connect(mapStateToProps, bindAction)(HomeView));
