import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import TextAriaValidation from '../../../components/TextAriaValidation';
import Rating from 'react-rating';
import List from '../List';
import HeadLine from '../../../layouts/PageLayout/HeadLine';
import PaperButton from 'react-paper-button';
import { setModal } from 'store/modal';
import ModalWrapper from '../../../components/ModalWrapper';
import { getArrays } from 'lib';

const animateTime = 100;


class Dish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      modal: false,
      saved: true,
      nextActive: false,
      xyz: false,
      dishes: false,
      types: false,
      feedbackData: false,
      ratings: false,
      errors: {}
    };

    this.getRatings();
  }

  getRatings() {
    this.props.socket.emit('order', { type: 'getRatings', data: {userId: this.props.user_id} })
  }

  listener = action => {
    //if (this.DishFeedbackRef) {
      switch (action.type) {
        case 'getDishesOk':
          const { dishes, types } = action.data;
          const { products } = this.props.dataArray;
          const ids = Object.keys(dishes);
          const active = types.length && products && dishes && ids.length ? +ids.filter(id => products[id] && products[id].type.includes(+types[0]))[0] : false;
          this.setState({ dishes, types, active, feedbackData: dishes[active] });
          break;
        case 'getRatingsOk':
          this.setState({
            ratings: action.data.ratings
          });
          console.log(this.state.ratings);
        break;
        case 'setDishRatingOk':
          const { feedbackData, dishes: oldDishes, nextActive, active: oldActive } = this.state;
          const { dish_id } = action.data;
          this.setState({
            saved: true,
            dishes: { ...oldDishes, [dish_id]: feedbackData },
            errors: {},
            modal: false,
            active: nextActive || oldActive,
            feedbackData: nextActive ? oldDishes[nextActive] : feedbackData
          });
          this.props.setModal({ message: action.data.message });
          break;
        case `${action.type}Err`:
          this.props.setModal({ message: action.data.message });
          break;
      }
    //}
  };

  getDishes = () => {
    this.props.socket.emit('rating', { type: 'getDishes', data: {} });
  };

  componentWillMount() {
    this.props.socket.on('rating', this.listener);
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('rating', this.listener);
    this.props.socket.removeListener('order', this.listener);
  }

  onChangeFeedbackData = (name, value) => this.setState({ feedbackData: { ...this.state.feedbackData, [name]: value }, saved: false });

  setNotification(changes, thetype) {
      const {user_id} = this.props;
      this.props.socket.emit('profile', {type: 'getForDashboard', data: {
            'subtype': 'setNotification', 
            'userId': user_id,
            'changes': changes,
            'thetype': thetype,
          } 
      });
  }

  componentDidMount() {
    if (this.DishFeedbackRef) {
      const { dataArray, socket, cc } = this.props;
      const types = ['products', 'typeList'].filter(el => !(el in dataArray));
      if (types.length) {
        getArrays(socket, types);
        cc && setTimeout(() => this.getDishes(), 50);
      }
      setImmediate(() => this.setState({ xyz: true }), 3 * animateTime);
      if (dataArray && dataArray.products && cc) {
        this.getDishes();
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.cc && nextProps.cc) {
      this.getDishes();
    }
  }

  closeModal = () => this.setState({ modal: false });

  saveData = () => {
    const { feedbackData, saved, active: dish_id } = this.state;
    const { socket, user_id } = this.props;
    const { feedback, rating } = feedbackData;
    if (saved || !feedback || !rating) {
      let errors = {};
      errors = !feedback ? { ...errors, 'feedback': 'Leave few words about dish' } : errors;
      errors = !rating ? { ...errors, 'rating': 'Please rate this dish' } : errors;
      this.setState({ errors, modal: false });
    } else {
      const data = {
        user_id,
        dish_id,
        ...feedbackData
      };
      socket.emit('rating', {
        type: 'setDishRating',
        data: {
          feedbackData: data
        }
      });

      this.setNotification('Feedback about dishes', 3);
    }
  };

  renderPlaceholder = () => {
    return (
      <div>
        <h4 className='thank-you'>You will be able to leave a review about the dishes after they are delivered to you.</h4>
      </div>
    );
  };

  render() {
    const { active, xyz, dishes, types, feedbackData, modal, saved, nextActive, errors, ratings } = this.state;
    const { dataArray } = this.props;
    const { products, typeList } = dataArray;
    const product = products ? products[active] : {};
    const changeActive = active => this.setState({ active, feedbackData: dishes[active], saved: true, modal: false, nextActive: false });
    const crating=(feedbackData.rating!=undefined&&feedbackData.rating!=null)?feedbackData.rating:((+ratings[+active]!=undefined)?+ratings[+active]:'');
    return (
      <div className={`d-flex main-holder flex-column ${xyz ? 'xyz-fin' : 'xyz'}`} ref={el => (this.DishFeedbackRef = el)}>
        <HeadLine
          title='About dishes'
          desc='Please leave your feedback'
        />
        <div className='container-fluid regular-page feedback-page dish-feedback'>
          {(active && dishes) ? <Fragment>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <List
                  onChange={active => saved ? changeActive(active) : this.setState({ modal: true, nextActive: active })}
                  dishes={dishes}
                  choice={+active}
                  types={types}
                  ratings={ratings}
                  products={products || {}}
                  typeList={typeList || {}}
                />
              </div>
              <div className='col-12 col-md-6'>
                <div className='inputs holder mt-3'>
                  <div className='time-programm-group w-100'>
                    <h5 className='duration2 line-h-initial'>Leave your rating and feedback about <span className='duration2 green'>{product.title}</span></h5>
                    <div className='letter-xs mb-1 light-dark-text'>Please add your testimonials about this dish.</div>
                    <div className='bg-gray-bordered padded rev-rating'>
                      <Rating
                        onChange={value => this.onChangeFeedbackData('rating', value)}
                        initialRating={crating || 0}
                        emptySymbol={<span className='icon-star mx-1 letter-xxx light-dark-text' />}
                        fullSymbol={<span className='icon-star mx-1 letter-xxx green' />}
                      />
                      {errors.rating && <span className='has-error letter-xs red-color'>{errors.rating}</span>}
                    </div>
                  </div>
                  <div className='time-programm-group w-100'>
                    <div className='letter-xs light-dark-text'>Please add your testimonials about this dish.</div>
                    <div className='bg-gray-bordered padded'>
                      <TextAriaValidation
                        value={feedbackData.feedback || ''}
                        name='feedback'
                        onChange={this.onChangeFeedbackData}
                        rows={3}
                        placeholder='Write your feedback...'
                        errors={errors}
                        inputClassName={'w-100 h-100 letter-xs b-rounded-blue shadowed custom-scrollbar'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment> : this.renderPlaceholder()}
        </div>
        {(active && dishes) && <div className='container-fluid bottom-row'>
          <div className='row step-holder dish-feedback bottom-buttons-holder'>            
            <div className='col-12 m-0 p-0'>
              <PaperButton onClick={this.saveData} className='button-regular transition next'>
                <span>Save</span>
              </PaperButton>
            </div>
          </div>
        </div>}
        <ModalWrapper
          show={modal}
          close={this.closeModal}
          md={4}
        >
          <div className={'order-modify-modal'}>
            <Fragment>
              <div className='text-center modal-message'>
                <div className='row modal-header-styled'>
                  <div className='col-12 title-block'>
                    <p>
                      <span className='ar-golr-l' />
                      <span className='m-title'>Are you sure?</span>
                      <span className='ar-golr-r' />
                    </p>
                  </div>
                </div>
                <div className='pl-5 pr-5'>
                  <p className='text-center'>{'Changes will not be saved.'}</p>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <PaperButton
                      className='place-order button-regular transition w-c-80 ml-auto'
                      onClick={this.saveData}
                    >
                      Save changes
                    </PaperButton>
                  </div>
                  <div className='col-6'>
                    <PaperButton
                      className='place-order button-regular transition w-c-80 mr-auto'
                      onClick={() => changeActive(nextActive)}
                    >
                      Discard changes
                    </PaperButton>
                  </div>
                </div>
              </div>
            </Fragment>
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

Dish.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired,
  setModal: PropTypes.func.isRequired,
  cc: PropTypes.bool
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  user_id: state.user.user_id,
  cc: state.user.cc
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(Dish));
