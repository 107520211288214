import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { setModal, clearModal, updateModal } from 'store/modal';
import sendImage from 'handler/sendImage';
import { spin, stop } from 'store/spinner';
import PaperButton from 'react-paper-button';
import Select from 'components/Select';
import { getDataArray } from 'store/dataArray';
import InputCalendar from 'components/InputCalendar';
import InputValidation from 'components/InputValidation';
import SelectValidation from 'components/SelectValidation';
import PrefsModal from 'components/Prefs';

class MainSettings extends Component {

	constructor(props) {
	    super(props);
	    this.state = { 
	      	errors: [],
          company: null, 
          birthday: null, 
          email: null, 
          phone: null,
          medical: null,
          
          street: null, 
          apts: null, 
          emirate: null, 
          area: null, 
          landmark: null,

          public_street: null, 
          public_apts: null, 
          public_emirate: null, 
          public_area: null, 
          public_landmark: null,

          work_street: null, 
          work_apts: null, 
          work_emirate: null, 
          work_area: null, 
          work_landmark: null,

          default_home: null,
          default_work: null,
          default_public: null,
          
          startDate: null,
          endDate: null,
          knowdate: true,
          pause: false,
          pause_start: null,
          pause_end: null,
          showPrefsModal: false,

          sms: false,
	    };

      this.getMainInfo();
      this.getPause();
      this.getSmsInfo();
      //this.afterPauseConfirm();
  }

  componentWillMount() {
      this.props.socket.on('profile', this.listeners);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ xyz: true }), 300);
    const { dataArray, socket } = this.props;
    const keys = ['typeList', 'dietList', 'planList', 'products', 'ingredientList', 'areasListFull'].filter(el => !(el in dataArray));
    keys.length && getDataArray({ keys, socket });
    if (!this.loaded) {
      socket.emit('progress', { type: 'get' });
      socket.emit('order', { type: 'get' });
      this.loaded = true;
    }
  }

  componentWillUnmount() {
      this.props.socket.removeListener('profile', this.listeners);
  }

	changeUser = (name, value, errorsToUnset) => 
  { 	
    	const {clearModal} = this.props;
    	this.setState({
    		[name]:value,
    	});
      setTimeout(() => this.foodPrefs(), 10);
  }

  changeUserInfo = (name, value, errorsToUnset) => 
  {   
      const {clearModal} = this.props;
      this.setState({
        [name]:value,
      });
      console.log(name);
      console.log(value);
      setTimeout(() => this.personalDetails(), 10);
  }

  changePauseInfo = (name, value, errorsToUnset) => 
  {   
      const {clearModal} = this.props;

      var save = true;
      if (name == 'startDate') {
        var start = moment().add(2, 'days');
        var unix = start.unix();
        console.log(unix);
        if (value < unix) {
          save = false;
          alert ('Sorry, we can pause your meal plan in a gap of 48hrs only. Please select another date. Thanks');
        }
      }

      if (save) {
        this.setState({
          [name]:value,
        });
      }
      console.log(name);
      console.log(value);
      setTimeout(() => this.pausePlan(), 10);
  }

  changeKnown = (val) => {
    this.setState({
      knowdate: val,
      endDate: null,
    });
    setTimeout(() => this.pausePlan(), 10);
  }

  changeUserAddress = (name, value, errorsToUnset) => 
  {   
      const {clearModal} = this.props;
      this.setState({
        [name]:value,
      });
      console.log(name);
      console.log(value);
      setTimeout(() => this.address(), 10);
  }

  changeMedicalInfo = (name, value, errorsToUnset) => 
  {   
      const {clearModal} = this.props;
      this.setState({
        [name]:value,
      });
      console.log(name);
      console.log(value);
      setTimeout(() => this.medicalConditions(), 10);
  }

  listeners = action => {
      console.log(action);
      const {clearModal, setModal} = this.props;
      const {message} = action.data;
      switch (action.type) {
        case 'getPauseOk':
          this.setState({
            pause: true,
            pause_start: action.data.startDate,
            pause_end: action.data.endDate,
          });
        break;
        case 'getSmsInfoOk':
          this.setState({
            sms: action.data.sms
          });
        break;
        case 'saveSmsInfoOk':
          clearModal();
          setModal({ headLine: 'SMS on Login', message: (
            <div>
              <p>{message}</p>
            </div>)
          });         
        break;
        case 'savePauseInfoOk':
          this.props.stop();
          clearModal();
          console.log(message);
          if (message == 'okokokok') {
            this.afterPauseConfirm();
          } else {
            setModal({ headLine: 'Pause Plan', message: (
              <div>
                <p>{message}</p>
              </div>)
            }); 
          }
          this.getPause();        
        break;
        case 'saveAddressInfoOk':
          clearModal();
          setModal({ headLine: 'Delivery Address', message: (
            <div>
              <p>{message}</p>
            </div>)
          });             
        break;
        case 'getMainSettingsInfoOk':
          const {phone, birthday, email, company, medical, homeAddress, workAddress, publicAddress, default_home, default_work, default_public} = action.data;
          var home_street = null;
          var home_apts = null;
          var home_emirate = null;
          var home_area = null;
          var home_landmark = null;

          var public_street = null;
          var public_apts = null;
          var public_emirate = null;
          var public_area = null;
          var public_landmark = null;

          var work_street = null;
          var work_apts = null;
          var work_emirate = null;
          var work_area = null;
          var work_landmark = null;

          if (homeAddress) {
            var {street, apartments, emirate, area, landmark} = homeAddress;
            home_street = street;
            home_apts = apartments;
            home_emirate = emirate;
            home_area = area;
            home_landmark = landmark;
          }

          if (workAddress) {
            var {street, apartments, emirate, area, landmark, default_work} = workAddress;
            work_street = street;
            work_apts = apartments;
            work_emirate = emirate;
            work_area = area;
            work_landmark = landmark;
          }

          if (publicAddress) {
              var {street, apartments, emirate, area, landmar, default_public} = publicAddress;
              public_street = street;
              public_apts = apartments;
              public_emirate = emirate;
              public_area = area;
              public_landmark = landmark;
          }

          this.setState({
            'phone': phone,
            'birthday': birthday,
            'email': email,
            'company': company,
            'medical': medical,
            
            'street': home_street,
            'apts': home_apts,
            'emirate': home_emirate,
            'area': home_area,
            'landmark': home_landmark,

            'work_street': work_street,
            'work_apts': work_apts,
            'work_emirate': work_emirate,
            'work_area': work_area,
            'work_landmark': work_landmark,

            'public_street': public_street,
            'public_apts': public_apts,
            'public_emirate': public_emirate,
            'public_area': public_area,
            'public_landmark': public_landmark,

            'default_home': default_home,
            'default_public': default_public,
            'default_work': default_work,
          });
        break;
        case 'savePrefsOk':
          clearModal();
          this.setState({showPrefsModal: false});
          setModal({ headLine: 'Food Preferences', message: (
            <div>
              <p>{message}</p>
            </div>)
          });                          
        break;
        case 'saveMedicalInfoOk':
          clearModal();
          setModal({ headLine: 'Medical Conditions', message: (
            <div>
              <p>{message}</p>
            </div>)
          });                          
        break;
        case 'saveMainInfoOk':
          clearModal();
          setModal({ headLine: 'Personal Details', message: (
            <div>
              <p>{message}</p>
            </div>)
          });                          
        break;
        
        case 'getErr':
          console.log(action.data.errors);
        break;
      } 
  };

  
  setNotification(changes, thetype) {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setNotification', 
          'userId': user.user_id,
          'changes': changes,
          'thetype': thetype,
        } 
    });
  }


  getMainInfo() {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'getMainSettingsInfo', 
          'userId': user.user_id,
        } 
    });
  }

  getSmsInfo() {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'getSmsInfo', 
          'userId': user.user_id,
        } 
    });
  }

  getPause() {
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'getPause', 
          'userId': user.user_id,
        } 
    });
  }

  saveUserInfo() {
    const {email, company, birthday, phone} = this.state;
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'saveMainInfo', 
          'userId': user.user_id,
          'email': email,
          'company': company,
          'birthday': birthday,
          'phone': phone,
        } 
    });
    this.setNotification('Personal Information', 1);
  }

  saveSecurityInfo() {
    var {sms} = this.state;
    const {user} = this.props;
    
    sms = !sms;
    this.setState({
      sms: sms
    });
    
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'saveSmsInfo', 
          'userId': user.user_id,
          'sms' : sms,
        } 
    });
    this.setNotification('Security changes: sms auth settings', 1);
  }

  saveMedicalInfo() {
    const {medical} = this.state;
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'saveMedicalInfo', 
          'userId': user.user_id,
          'medical': medical,
        } 
    });
    this.setNotification('Medical Conditions', 1);
  }

  savePauseInfo() {
    const {startDate, endDate} = this.state;
    const {user,spin} = this.props;
  	spin();
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'savePauseInfo', 
          'userId': user.user_id,
          'startDate': startDate,
          'endDate': endDate,
        } 
    });
    this.setNotification('Pause', 1);
  }

  sendPauseRequest() {
    const {pause_request} = this.state;
    const {user, setModal} = this.props;
    /*this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'sendPauseRequest', 
          'userId': user.user_id,
          'text': pause_request,
        } 
    });*/
    
    this.setNotification('Pause Request:' + pause_request, 2);
    setModal({ headLine: 'Thank you', message: 'Request sent well to our team. Your meal plan will be paused according to your given notes'});
  }

  saveAddressInfo() {
    const {street, apts, area, emirate, landmark} = this.state;
    const {work_street, work_apts, work_area, work_emirate, work_landmark} = this.state;
    const {public_street, public_apts, public_area, public_emirate, public_landmark} = this.state;
    const {default_home, default_work, default_public} = this.state;
    const {user} = this.props;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'saveAddressInfo', 
          'userId': user.user_id,
          'street': street,
          'apts': apts,
          'area': area,
          'emirate': emirate,
          'landmark': landmark,

          'work_street': work_street,
          'work_apts': work_apts,
          'work_area': work_area,
          'work_emirate': work_emirate,
          'work_landmark': work_landmark,

          'public_street': public_street,
          'public_apts': public_apts,
          'public_area': public_area,
          'public_emirate': public_emirate,
          'public_landmark': public_landmark,

          'default_home': default_home,
          'default_work': default_work,
          'default_public': default_public,
        } 
    });
    this.setNotification('Delivery Address', 1);
  }

  showPrefs() {
    this.setState({
      showPrefsModal: true
    });
  }

  doLastPauseRequests(type) {
    const {setModal, clearModal} = this.props;
    switch(type) {
      case 'manual':
        this.setNotification('User will review the mealplan manually after the pause', 1);
      break;

      case 'request':
        this.setNotification('User wants to have some review of the after pause menu from our team', 1);
      break;
    }

    clearModal();
    setModal({ headLine: 'Thank you', message: (
            <div>
              <p>Your meal plan is paused, have a good day!</p>
            </div>)
    });        
  }

  afterPauseConfirm() {
      const {setModal} = this.props;
      setModal({ headLine: 'Thank You', message: (
        <div>
        <p> Your meal plan is paused for your requested dates. New days have been added to the end of your plan. </p>
        <h3 className="bold">YOUR ACTION REQUIRED</h3>
        <p>For the new days added at the end of your plan, default dishes are selected. Your preferences are NOT considered. You need to manually select your preferred dishes. Alternatively, send us a request to adjust the menu for your preferences.</p>
        <p align="center">
            <PaperButton className='button-regular next transition'
              onClick={ (e) => {this.doLastPauseRequests('request')}}
            >
            <span className="small-btn-text">Help! Send request to Basiligo team</span>
            </PaperButton>
        </p>

        <p align="center">
            <PaperButton className='button-regular next transition'
              onClick={ (e) => {this.doLastPauseRequests('manual')}}
            >
            <span className="small-btn-text">It’s ok I will change the meals manually</span>
            </PaperButton>
        </p>

        </div>
      )});
  }
	

	address() {
		const {setModal, dataArray} = this.props;
    const {street, apts, emirate, area, landmark, errors} = this.state;
    const {work_street, work_apts, work_emirate, work_area, work_landmark} = this.state;
    const {public_street, public_apts, public_emirate, public_area, public_landmark} = this.state;
    const {default_home, default_work, default_public} = this.state;
    const {ingredientList, allEmirates, areasList, areasListFull} = dataArray;
    
    var areas = [];
    var work_areas = [];
    var public_areas = [];
    
    if (emirate && typeof(emirate) != 'undefined') {
      areasListFull.map( (el, idx) => {
        
        if (el.emirate_id == emirate) {
          areas[+el.id] = el.name;
        }

        if (el.emirate_id == work_emirate) {
          work_areas[+el.id] = el.name;
        }

        if (el.emirate_id == public_emirate) {
          public_areas[+el.id] = el.name;
        }

      });
    }

		setModal({ headLine: 'Delivery Address', message: (
          <div className="addresses-fields">
            <h3>Home Address</h3>
            <div className="address-edit-div">
                <InputValidation
                  name={'street'}
                  value={street || ''}
                  placeholder='Street'
                  onChange={ (name, value) => this.changeUserAddress('street', value)}
                  errors={errors}
                />
                <InputValidation
                  name={'apts'}
                  value={apts || ''}
                  placeholder='Apartment or Villa #'
                  onChange={ (name, value) => this.changeUserAddress('apts', value)}
                  errors={errors}
                />
                <SelectValidation
                  name={'language'}
                  value={emirate || null}
                  onChange={(name, value) => this.changeUserAddress('emirate', value)}
                  errors={errors}
                  list={allEmirates}
                  emptyValue={'Select Emirate'}
                  canEmpty
                />
                <SelectValidation
                  name={'language'}
                  value={area || null}
                  onChange={(name, value) => this.changeUserAddress('area', value)}
                  errors={errors}
                  list={areas}
                  emptyValue={'Select Area'}
                  canEmpty
                />
                <InputValidation
                  name={'landmark'}
                  value={landmark || ''}
                  placeholder='Nearest Landmark'
                  onChange={ (name, value) => this.changeUserAddress('landmark', value)}
                  errors={errors}
                />
                <div className="addr-checkbox">
                <input type="checkbox" value={default_home ? 1 : 0} checked={default_home ? 'checked' : ''} onChange={ (e) => {
                  this.changeUserAddress('default_home', !e.target.value) }
                }/>
                <label>Set as Default</label>
                </div>
            </div>

            <h3>Work Address</h3>
            <div className="address-edit-div">
                <InputValidation
                  name={'street'}
                  value={work_street || ''}
                  placeholder='Street'
                  onChange={ (name, value) => this.changeUserAddress('work_street', value)}
                  errors={errors}
                />
                <InputValidation
                  name={'apts'}
                  value={work_apts || ''}
                  placeholder='Apartment or Villa #'
                  onChange={ (name, value) => this.changeUserAddress('work_apts', value)}
                  errors={errors}
                />
                <SelectValidation
                  name={'language'}
                  value={work_emirate || null}
                  onChange={(name, value) => this.changeUserAddress('work_emirate', value)}
                  errors={errors}
                  list={allEmirates}
                  emptyValue={'Select Emirate'}
                  canEmpty
                />
                <SelectValidation
                  name={'language'}
                  value={work_area || null}
                  onChange={(name, value) => this.changeUserAddress('work_area', value)}
                  errors={errors}
                  list={work_areas}
                  emptyValue={'Select Area'}
                  canEmpty
                />
                <InputValidation
                  name={'landmark'}
                  value={work_landmark || ''}
                  placeholder='Nearest Landmark'
                  onChange={ (name, value) => this.changeUserAddress('work_landmark', value)}
                  errors={errors}
                />

                <div className="addr-checkbox">
                <input type="checkbox" value={default_work ? 1 : 0} checked={default_work ? 'checked' : ''} onChange={ (e) => this.changeUserAddress('default_work', !e.target.value) }/>
                <label>Set as Default</label>
                </div>
            </div>

            <h3>Public Address</h3>
            <div className="address-edit-div">
                <InputValidation
                  name={'street'}
                  value={public_street || ''}
                  placeholder='Street'
                  onChange={ (name, value) => this.changeUserAddress('public_street', value)}
                  errors={errors}
                />
                <InputValidation
                  name={'apts'}
                  value={public_apts || ''}
                  placeholder='Apartment or Villa #'
                  onChange={ (name, value) => this.changeUserAddress('public_apts', value)}
                  errors={errors}
                />
                <SelectValidation
                  name={'language'}
                  value={public_emirate || null}
                  onChange={(name, value) => this.changeUserAddress('public_emirate', value)}
                  errors={errors}
                  list={allEmirates}
                  emptyValue={'Select Emirate'}
                  canEmpty
                />
                <SelectValidation
                  name={'language'}
                  value={public_area || null}
                  onChange={(name, value) => this.changeUserAddress('public_area', value)}
                  errors={errors}
                  list={public_areas}
                  emptyValue={'Select Area'}
                  canEmpty
                />
                <InputValidation
                  name={'landmark'}
                  value={public_landmark || ''}
                  placeholder='Nearest Landmark'
                  onChange={ (name, value) => this.changeUserAddress('public_landmark', value)}
                  errors={errors}
                />
                <div className="addr-checkbox">
                <input type="checkbox" value={default_public ? 1 : 0} checked={default_public ? 'checked' : ''} onChange={ (e) => this.changeUserAddress('default_public', !e.target.value) }/>
                <label>Set as Default</label>
                </div>
                <p align="center">
                    <PaperButton className='button-regular next transition'
                     onClick={ (e) => {this.saveAddressInfo();}}
                    >
                        <span>Save</span>
                    </PaperButton>
                </p>
            </div>
          </div>
        )});
	}

  security() {
    const {setModal} = this.props;
    const {sms} = this.state;
    setModal({ headLine: 'Security Settings', message: (
            <div className="popup-dash-calendar-fix">
                <b>Register for 2-step verfication to secure your account.</b><br/><br/>
                SMS on Login: {sms ? 'Enabled' : 'Disabled'} <br/><br/>
                <p align="center">
                    <PaperButton className='button-regular next transition'
                      onClick={ (e) => {this.saveSecurityInfo(); }}
                    >
                        <span>{sms ? 'Disable SMS on Login' : 'Enable SMS on Login'}</span>
                    </PaperButton>
                </p>
            </div>
        )});
  }

	personalDetails() {
		const {setModal} = this.props;
    const {company, birthday, email, phone, errors} = this.state;
		setModal({ headLine: 'Personal Details', message: (
            <div className="popup-dash-calendar-fix">
                <InputCalendar
                  value={birthday || ''}
                  fullYear
                  locale='en'
                  name='birthday'
                  onChange={birthday => this.changeUserInfo('birthday', birthday)}
                  errors={errors}
                  disable={false}
                  placeholder={'Birthday'}
                  defaultValue={moment().subtract(18, 'y')}
                />
                <InputValidation
                  name={'email'}
                  value={email || ''}
                  placeholder='Email'
                  onChange={ (name, value) => {
                    this.changeUserInfo('email', value);
                    console.log(value);
                  }}
                  errors={errors}
                />
                <InputValidation
                  name={'phone'}
                  value={phone}
                  mask='tel'
                  maskProps={{
                    mask: '+\\9\\7\\1(99)999 99 99 99',
                    permanents: [0, 1, 2, 3, 4, 7, 11, 14, 17],
                    alwaysShowMask: true,
                    maskChar: ' '
                  }}
                  placeholder='Mobile Number'
                  onChange={ (name, value) => this.changeUserInfo('phone', value)}
                  errors={errors}
                />

                <InputValidation
                  name={'company'}
                  value={company || ''}
                  placeholder='Company'
                  onChange={ (name, value) => this.changeUserInfo('company', value)}
                  errors={errors}
                />
                
                <p align="center">
                    <PaperButton className='button-regular next transition'
                      onClick={ (e) => {this.saveUserInfo(); }}
                    >
                        <span>Save</span>
                    </PaperButton>
                </p>
            </div>
        )});
	}

	medicalConditions() {
		const {setModal} = this.props;
    const {medical} = this.state;
		setModal({ headLine: 'Medical Conditions', message: (
            <div>
                <textarea className="medical-text-area" value={medical} onChange={ (e) => { this.changeMedicalInfo('medical', e.target.value)}}/>
                <p align="center">
                    <PaperButton className='button-regular next transition'
                       onClick={ (e) => {this.saveMedicalInfo();}}
                    >
                        <span>Save</span>
                    </PaperButton>
                </p>
            </div>
        )});
	}

	pausePlan() { 
		const {setModal} = this.props;
    const {startDate, endDate, errors, knowdate, pause, pause_start, pause_end, pause_request} = this.state;
    
    var start = moment().add(3, 'days');
    var end = moment().add(10, 'days');

    if (!pause) {
		    setModal({ headLine: 'Pause Plan', message: (
            <div className="popup-dash-calendar-fix">
                <InputCalendar
                  value={startDate || ''}
                  fullYear
                  locale='en'
                  name='startDate'
                  onChange={startDate => this.changePauseInfo('startDate', startDate)}
                  errors={errors}
                  disable={false}
                  placeholder={'Pause Start Date'}
                  defaultValue={start}
                />

                {knowdate && 
                  (<InputCalendar
                    value={endDate || ''}
                    fullYear
                    locale='en'
                    name='endDate'
                    onChange={endDate => this.changePauseInfo('endDate', endDate)}
                    errors={errors}
                    disable={false}
                    placeholder={'Pause End Date'}
                    defaultValue={end}
                  />)
                }
                {knowdate && <a className="date-changer" onClick={ (e) => {
                  this.changeKnown(false);
                } }>I don't know the end date</a>}
                {!knowdate && <a className="date-changer" onClick={ (e) => {
                  this.changeKnown(true);
                } }>I want to set the end date</a>}
                <p align="center">
                    <PaperButton className='button-regular next transition'
                    onClick={ (e) => {this.savePauseInfo();}}
                    >
                        <span>Save</span>
                    </PaperButton>
                </p>
                <br/><br/>
                Or send request:

                <textarea className="medical-text-area" value={pause_request} onChange={ (e) => { this.changePauseInfo('pause_request', e.target.value)}}/>
                <p align="center">
                    <PaperButton className='button-regular next transition'
                       onClick={ (e) => {this.sendPauseRequest();}}
                    >
                        <span>Send Request</span>
                    </PaperButton>
                </p>
            </div>
        )});
    } else {
        setModal({ headLine: 'Pause Plan', message: (
            <div className="bold-texts">
              <span>Your mealplan is on pause:</span> <br/>
              <b>From:</b> {moment.unix(pause_start).format('DD MMM')} <b>To:</b> {(pause_end && moment.unix(pause_end).format('DD MMM')) || 'Indefinite time'}
              <br/><br/>
              <p>If you want to unpause your mealplan, contact us by phone please.</p>
            </div>
        )});
    }
	}

  	/*getHistory = () => {
  		const {spin} = this.props;
  		spin();
	    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
	        'subtype': 'getHistory', 
	        'userId': this.props.user.user_id,
	    } });
  	}*/

	render() {
		const {showPrefsModal} = this.state;
    const {dataArray, user} = this.props;
		return(
					<div className="links-wrap position-relative">
                        <div className="bg-links"></div>
                        <div className="row">
                          <div className="col-7 offset-5 my-auto set-links">
                            <ul>
                              <li><a className="pointer" onClick={e => this.showPrefs()}>Food Preferences</a></li>
                              <li><a className="pointer" onClick={e => this.address()}>Address</a></li>
                              <li><a className="pointer" onClick={e => this.personalDetails()}>Personal Details</a></li>
                              <li><a className="pointer" onClick={e => this.medicalConditions()}>Medical Conditions</a></li>
                              <li><a className="pointer" onClick={e => this.pausePlan()}>Pause Plan</a></li>
                              <li><a className="pointer" onClick={e => this.security()}>Security Settings</a></li>
                            </ul>
                          </div>
                        </div>
                        {showPrefsModal &&
                          <PrefsModal
                            close={() => {
                              this.setState({ showPrefsModal: false });
                            }}
                            
                            headLine="Food Preferences"
                            dataArray={dataArray}
                            user={user}
                            socket={this.props.socket}
                          />
                        }
                    </div>
		)
	} 
}

MainSettings.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setModal: obj => dispatch(setModal(obj)),
  updateModal: obj => dispatch(setModal(obj)),
  clearModal: () => dispatch(clearModal()),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(mapStateToProps, mapDispatchToProps)(MainSettings));