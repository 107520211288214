import React, { Component } from 'react';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xyz: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.SupportViewRef && this.setState({ xyz: true }), 300);
  }

  render() {
    const { xyz } = this.state;
    return (
      <div ref={el => (this.SupportViewRef = el)} className={`bg-1-1 main-page main-holder contact-page  ${xyz ? 'xyz-fin' : 'xyz'}`}>
        <div className='container-fluid title-holder blog-heading-menu'>
          <div className='row blog-heading menu-heading'>
            <div className='col-12 d-flex flex-column justify-content-center heading title-block blog'>
              <h1>Contact Us</h1>
              <p>
                <span className='ar-golr-l' />
                <span>Get in touch</span>
                <span className='ar-golr-r' />
              </p>
            </div>
          </div>
        </div>
        <div className='article-wrapper about single-blog-wrapper row support-holder about-holder'>
          <div className='col-12 col-lg-6 single-blog-page second-bg' />
          <div className='col-12 col-lg-6 single-blog-page p-5'>
            <div className='row'>
              <h5 className='col-12 green'>
                You can order online from our main kitchens in Abu Dhabi. You can also visit our outlet in Shams BOUTIK Mall in Reem Island, Abu Dhabi.
                Deliveries are only to Abu Dhabi.
              </h5>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 col-lg-6 dsc'>
                    <h2 className='green'>Address</h2>
                    <p className='mb-0'>7 days a week</p>
                    <p className='mb-0'>8: 00 AM – 11: 00 PM</p>
                    <p className='mb-0'>Shams Boutik Mall, Ground Floor</p>
                    <p className='mb-0'>  Next to Waitrose Suppermarket</p>
                  </div>
                  <div className='col-12 col-lg-6 dsc'>
                    <h2 className='green'>Phone</h2>
                    <p className='mb-0'>Reem Island Branch:</p>
                    <p className='mb-0'>02-678-9782</p>
                    <p className='mb-0'>Main Kitchen Delivery:</p>
                    <p className='mb-0'>+971 50 245 3860</p>
                  </div>
                  <div className='col-12 col-lg-6 dsc'>
                    <h2 className='green mt-4'>Main Kitchen Delivery</h2>
                    <p className='mb-0'>Saturday – Thursday</p>
                    <p className='mb-0'>10:00 AM – 10:00 PM</p>
                    <p className='mb-0'>P.O. Box 91707 Abu Dhabi, UAE</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactUs;
