import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HorizontalScroll from 'components/HorizontalScroll';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { setMealPlan } from 'store/mealPlan';
import { setPlanParams } from 'store/planParams';
import { setCustomMealPlan } from 'store/customMealPlan';
import { setCustomPlanParams } from 'store/customPlanParams';
import { setCustomPlanSides } from 'store/customPlanSides'; 
import { intersect } from 'lib';
import Rating from 'react-rating';
import moment from 'moment';
import ToolTip from 'components/ToolTip';
import noImage from 'assets/no_img.svg';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import LeftArrow from 'assets/left-arrow.svg';
import Leaf from 'assets/leaf.svg';
import RightArrow from 'assets/right-arrow.svg';
import caloriesIcon from 'assets/calories-ic.svg';
import LoveEmojiIcon from 'assets/emoji-ic01.svg';
import GoodEmojiIcon from 'assets/emoji-ic02.svg';
import OkEmojiIcon from 'assets/emoji-ic03.svg';
import BadEmojiIcon from 'assets/emoji-ic04.svg';
import CardEmojiIcon from 'assets/front.svg';
import { setModal, clearModal } from '../../../../../store/modal';
import PaperButton from 'react-paper-button';
import { Select2 } from "select2-react-component";
import { spin, stop } from 'store/spinner';
import { splitBsProps } from 'react-bootstrap/lib/utils/bootstrapUtils';
import SidesModal from './SidesModal';
import SideDishModal from './SideDishModal';
import RatingModal from './RatingModal';
import DishDescription from 'components/DishDescription';
import SelectInput from 'components/Select';
const animateTime = 100;

class Day extends Component {
  constructor(props) {
    super(props);
    const activeType = props.planParams.mealType.reduce((acc, cur) => +cur < +acc ? +cur : +acc, Infinity);
    const {stop} = props;
    stop();
    this.state = {
      xyz: false,
      sides: null,
      basicside: null,
      oilside: null,
      buyside: null,
      typeEdit: false,
      showinfo: [],
      dislikeproducts:[],
      allergyproducts:[],
      activeType,
      ratings:props.ratings,
      checkMealPlan: []
    };

  }

  saveRating(product_id, rating) {
    const {ratings}=this.state;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setDishRating',
          'userId': this.props.user.user_id,
          'productId': product_id,
          'rating': rating,
        }
    });
    if(ratings[product_id]!=undefined){
        ratings[product_id]=rating;
        let ratings1=ratings;
        this.setState({
          ratings:ratings1
        })
      }
    // var changes = 'dish rating for dish with id #'+product_id;
    // this.props.socket.emit('profile', {type: 'getForDashboard', data: {
    //       'subtype': 'alertMail',
    //       'userId': this.props.user.user_id,
    //       'changes': changes,
    //     }
    // });

  }

  sidesChange(value, date, dish_id, type, type_id) {
    const {clearModal} = this.props;

    this.setState({
      [type]: value
    });

    clearModal();
    if (type == 'buyside') {
      setTimeout(() => this.buySidesModal(date, dish_id, type_id), 10);
    } else {
      setTimeout(() => this.sidesModal(date, dish_id), 10);
    }
  }

  saveSides(sides, date, dish_id) {
    const {planParams, clearModal} = this.props;
    const {basicside, oilside} = this.state;
    var sides = [];
    var type='';
    if (basicside) {
      sides.push(basicside);
    }

    if (oilside) {
      sides.push(oilside);
    }

    this.setState({
      sides: sides
    });

    if(this.SidesModalRef){
      sides=this.SidesModalRef.state.sides;
      type=this.SidesModalRef.state.type;
   }

    var data = {sides: sides, date: date, dishId: dish_id, orderId: planParams.id, userId: this.props.user.user_id,sidetype:type}
    console.log(data);
    this.props.socket.emit('order', {type: 'saveUserSides', data: data});
    clearModal();
  }

  updateNotselectableProducts = (product_id,type) => {
    var {dislikeproducts,allergyproducts} = this.state;
    if(type=='allergy'){
      if (allergyproducts.indexOf(product_id) == -1) {
        allergyproducts.push(product_id);
        this.setState({
          allergyproducts: allergyproducts,
        });
      }
    }else if(type=='dislike'){
      if (dislikeproducts.indexOf(product_id) == -1) {
           dislikeproducts.push(product_id);
          this.setState({
            dislikeproducts: dislikeproducts,
          });
      }

    }
  }
  editDay = (timestamp,keys) => {
    const { setUnsavedVal,editdaylist }=this.props;
    console.log(editdaylist);
    if(Object.keys(editdaylist).length<=0){
        this.setState({
                typeEdit: true
            });
      setUnsavedVal('push',timestamp+keys)
    }else{
      setUnsavedVal('push',timestamp+keys,true);
    }
}
  setNotification(changes, thetype) {
      const {user} = this.props;
      this.props.socket.emit('profile', {type: 'getForDashboard', data: {
            'subtype': 'setNotification',
            'userId': user.user_id,
            'changes': changes,
            'thetype': thetype,
          }
      });
  }

  componentDidMount() {
    if (this.MainDayModRef) {
      const nextActive = moment().utc().startOf('day').add(72, 'h');
      if (this.props.date && this.props.date.isSame(nextActive)) {
        this.MainDayModRef.parentElement.scrollTop = this.MainDayModRef.offsetTop;
      }
      setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    }
  }

  componentWillReceiveProps(next) {
    if (this.MainDayModRef && !this.state.activeType && next.planParams && next.planParams.mealType) {
      this.setState({ activeType: next.planParams.mealType.reduce((acc, cur) => +cur < +acc ? +cur : +acc, Infinity) });
    }
  }

  renderTypeList = (buffered,bagName) => {
    const { activeType, typeEdit,ratings } = this.state;
    const { dataArray, planParams, placeOrder, date, pause, pause_start, pause_end, custom,customPlaceOrder,setUnsavedVal } = this.props;
    const { typeList } = dataArray;

    var paused = false;
    let unix = date.unix();
    if (pause) {
      if (unix >= pause_start && !pause_end) {
        paused = true;
      }

      if (unix >= pause_start && unix <= pause_end) {
        paused = true;
      }
    }

    const types = planParams['mealType'] || [];
    const list = types.sort((a, b) => typeList[a].order - typeList[b].order).map(typeId => {
      const type = typeList && typeList[typeId];
      if(type.parent_id==0){
      return (
        <li
          key={typeId}
          className={+typeId === +activeType ? 'active' : ''}
          onClick={() => this.setState({ activeType: +typeId })}
        >
          {type.title}
        </li>
      );
      }
    }).filter(function (el) {
      return el != undefined;
    });   
    if (typeEdit && !buffered && !paused) {
      return (
        <Fragment>          
          <ul className='dish-groups pl-0 mb-0 edit-enabled'>
            {list}
            <li
              className='bg-gold'
              onClick={() => {
                this.setState({ typeEdit: false });
                //this.caloriesCalculation();
                setUnsavedVal('pop',unix+bagName);
                {!custom && placeOrder('no',unix,activeType) }
                {custom && customPlaceOrder()}
                this.setNotification('mealplan menu edit', 1);
              }}
            >
                        Save All Changes
            </li>
          </ul>        
          <div className="dishcate">
             
                  <Carousel
                    arrowLeft={<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>}
                    arrowRight={<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>}
                    addArrowClickHandler
                    slidesPerPage={2}
                    slidesPerScroll={1}
                    draggable ={false}
                    slides={list}
                    //slides={[]}
                    breakpoints={{
                        992: { // these props will be applied when screen width is less than 1000px
                          slidesPerPage: 2,
                          slidesPerScroll:1,
                        },
                        479: {
                          slidesPerPage: 2,
                          slidesPerScroll:1,
                        },
                    }}
                    />
                <li
              className='bg-gold'
              onClick={() => {
                this.setState({ typeEdit: false });
                //this.caloriesCalculation();
                setUnsavedVal('pop',unix+bagName);
                {!custom && placeOrder('no',unix,activeType) }
                {custom && customPlaceOrder()}
                this.setNotification('mealplan menu edit', 1);
              }}
            >
                        Save All Changes
            </li>
            <p className='click-on mobile_show'>Click on the "Item card" to make selection.</p>
            </div>        
          <p className='click-on desktop_show'>Click on the "Item card" to make selection.</p>
        </Fragment>
      );
    } else if (!typeEdit && !buffered && !paused) {
      return (
        <ul className='dish-groups pl-0 mb-0'>
          <li
            onClick={() => this.editDay(unix,bagName)}
          >
                    Change items</li>
          <li className='can-change'>You can change the selection on your own</li>
        </ul>
      );
    } else if (buffered && !paused) {
      return (
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            <li className='can-change no-border'>You cannot edit or skip this day. Items are delivered or in the process of creation.</li>
          </ul>
        </Fragment>
      );
    } else if (paused) {
      return (
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            <li className='can-change no-border'>You cannot edit or skip this day. The day is on pause.</li>
          </ul>
        </Fragment>
      );
    } else if (custom) {
      return (
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            <li className='can-change no-border delivery-bage'>Please contact our team to change dishes or date for the additional delivery</li>
          </ul>
        </Fragment>
      );
    }
  };

  sidesModal = (date, dish_id,type_id,bagName) => {
    const {setModal, sidesList, usersides, planParams, oilList,dataArray} = this.props;
    const {products}=dataArray;
    var {sides, basicside, oilside} = this.state;
    /*if (!sides && usersides) {
      sides = [];
      if (typeof(usersides[date]) != 'undefined') {
        if (typeof(usersides[date][dish_id]) != 'undefined') {
          Object.keys(usersides[date][dish_id]).map( (el, idx) => {
            sides.push(usersides[date][dish_id][el].side_id);
          });
        }
      }
    }*/

    setModal({ headLine: 'Change your side', message:
      (
        <div>
            {<SidesModal
                usersides={usersides}
                date={date}
                sidesList={sidesList}
                orderId={planParams.id}
                typeId={type_id}
                bagName={bagName}
                type={'alternative'}
                productId={dish_id}
                products={products}
                socket={this.props.socket}
                ref={el => (this.SidesModalRef = el)}
              />}
                  {/* {sidesList && <Select2
                    placeholder='Side'
                    data={sidesList}
                    multiple={false}
                    value={basicside || ''}
                    update={
                      (value) => this.sidesChange(value, date, dish_id, 'basicside')
                    }>
                  </Select2>} */}

                  {(planParams.diet == 4) && oilList && <Select2
                    placeholder='Oil Side'
                    data={oilList}
                    multiple={false}
                    value={oilside || ''}
                    update={
                      (value) => this.sidesChange(value, date, dish_id, 'oilside')
                    }>
                  </Select2>}

                  <p align="center">
                  The above is individual calories. Please see the total calories after the selection of the side
                  <PaperButton onClick={() => this.saveSides(sides, date, dish_id)} className='button-small transition next'>
                    <span>Save</span>
                  </PaperButton>
                  </p>
        </div>
      )
    });
  }

  addToCart(dish_id, to_dish_id, type_id, date) {
    const {planParams} = this.props;
    this.props.socket.emit('order', { type: 'addToCart', data: {userId: this.props.user.user_id, dishId: dish_id, toDishId: to_dish_id, typeId: type_id, date: date, orderId: planParams.id } });
    const {clearModal} = this.props;
    clearModal();
  }

  toggleLike=(productId,type)=>{
    this.props.socket.emit('profile', { type: 'setDishlike', data: {userId:this.props.user.user_id, dishId: productId,action:type}});
  }
  showDishDetails= (productId) =>{
    const { setModal , dataArray} = this.props;
    const { products } =dataArray;
   const { ratings }=this.state;
   setModal({ headLine: '', message:
     (<DishDescription
                         product={{ ...products[productId], id: productId }}
                         ingredients={dataArray['ingredientList'] || {}}
                         ratings={ratings}
                         onClick={() => {
                           // this.hide('showDescription');
                           // this.hideToggler(true);
                         }}
                       /> )
                     });
 }
  rateDishModal=(productId)=>{
    const { setModal} = this.props;
    const {ratings}=this.state;
    var rate=ratings[productId]?ratings[productId]:0;
    setModal({ headLine: 'Your feedback is valuable', message:
      (
        <RatingModal
           productId={productId}
           rating={rate}
           saveRating={(product_id, rating,feedback=null)=>{
            const {clearModal} = this.props;
            this.props.socket.emit('profile', {type: 'getForDashboard', data: {
                  'subtype': 'setDishRatingNew',
                  'userId': this.props.user.user_id,
                  'productId': product_id,
                  'rating': rating,
                  'feedback':feedback
                }
            });
            if(ratings[product_id]!=undefined){
                ratings[product_id]=rating;
                let ratings1=ratings;
                this.setState({
                  ratings:ratings1
                })
              }
              clearModal();
            }
            }
        />
      )
    });
  }

  buySidesModal = (date, dish_id, type_id,bagName) => {
    const {setModal, planParams,dataArray, buySidesList,usersides} = this.props;
    const {products}=dataArray;
    var {buyside} = this.state;
    setModal({ headLine: 'Change your fixed side', message:
      (
        <div>
                  {/* {buySidesList && <Select2
                    placeholder='Side'
                    data={buySidesList}
                    multiple={false}
                    value={buyside || ''}
                    update={
                      (value) => this.sidesChange(value, date, dish_id, 'buyside', type_id)
                    }>
                  </Select2>} */}
                  {<SidesModal
                        usersides={usersides}
                        date={date}
                        sidesList={buySidesList}
                        orderId={planParams.id}
                        typeId={type_id}
                        multiple={false}
                        bagName={bagName}
                        type='addons'
                        productId={dish_id}
                        products={products}
                        socket={this.props.socket}
                        ref={el => (this.SidesModalRef = el)}
                      />}
                  <p align="center">
                  The above is individual calories. Please see the total calories after the selection of the side
                  {/* <PaperButton onClick={() => this.addToCart(buyside, dish_id, type_id, date)} className='button-small transition next'>
                    <span>Add To Cart</span>
                  </PaperButton> */}
                  <PaperButton onClick={() => this.saveSides(buyside, date, dish_id)} className='button-small transition next'>
                    <span>Save</span>
                  </PaperButton>
                  </p>
        </div>
      )
    });
  }
  confirmSkipModal = (date) => {
    const {setModal,skipTheDay,clearModal} = this.props;

     setModal({ headLine: 'Are you sure?', message:
       (
         <div>
           <ul className="list">
             <li>This action cannot be reversed.</li>
             <li>A day will be added to the end of your plan.</li>
             <li>Default dishes will be automatically selected unless you change the dishes according to your preferences.</li>
             <li>Please inform us by message in case you want us to check the menu</li>
           </ul>
             <p align="center">
                   <PaperButton onClick={() =>{skipTheDay(date);clearModal();}} className='button-small transition next'>
                     <span>Yes</span>
                   </PaperButton>
                   </p>
            <p align="center">
                   <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                     <span>No</span>
                   </PaperButton>
                   </p>
         </div>
       )
     });
   }
  sameDishIsSelected = (mealtype, dishid, bagname='') => {
    const { mealPlan, goMenu, date, setCustomMealPlan, setCustomPlanParams, planParams, dataArray, customPlans, ratings, dietproducts, custom, customdays, usersides, setModal } = this.props;
    let unix = date.unix(); // so in the products card we will have the time stamp
    let dishExist = '';
    let alertmsg = 'Please note, you have selected the same dish on the other bag!';
    var mealTypeArr = {1:'Breakfast',2:'Morning Snack',3:'Lunch',4:'Evening Snack',5:'Dinner'}
    if(bagname != ''){
      Object.keys(mealPlan[unix]).map((type) => {
        if(type == mealtype && dishid == mealPlan[unix][type]){
          dishExist = 2;
          return '';
        }
      });
      if(customdays.hasOwnProperty(unix)){
      Object.keys(customdays[unix]).map((bag)=>{
        Object.keys(customdays[unix][bag]).map((type)=>{
          if(type == mealtype && dishid == customdays[unix][bag][type]){
            dishExist = 2;
            return '';
          }
        })
      });
      // SAME PANEL CHECK FOR ADDITIONAL BAG
      Object.keys(customdays[unix][bagname]).map((val)=>{
        if(dishid == customdays[unix][bagname][val]){
          dishExist = 2;
          alertmsg = "Please note you have selected same dish for "+ mealTypeArr[val] +". Do you want to select same Dish? "
          return '';
        }
      });
    }
    }else{
       if(customdays.hasOwnProperty(unix)){
      Object.keys(customdays[unix]).map((bag)=>{
        Object.keys(customdays[unix][bag]).map((type)=>{
          if(type == mealtype && dishid == customdays[unix][bag][type]){
            dishExist = 2;
            return '';
          }
        })
      });
      // SAME PANEL
      Object.keys(mealPlan[unix]).map((type) => {
        if(dishid == mealPlan[unix][type]){
          dishExist = 2;
          alertmsg = "Please note you have selected same dish for "+ mealTypeArr[type] +". Do you want to select same Dish? "
          return '';
        }
      });
    }
    }
    return {'dishExist':dishExist,'msg':alertmsg};
  }

  renderCustomProduct = (bagName,buffered) => {
    const { activeType, typeEdit, showinfo } = this.state;
    const { goMenu, date, setCustomMealPlan, setCustomPlanParams, planParams, dataArray, customPlans,customPlanSides, ratings, dietproducts, custom, customdays, usersides, setModal, user } = this.props;
    const { preferences, allergies, dislikes, eliminate_groups, dishlikes, dishdislikes } = user;
    const { typeList, dishLabels,ingredientList } = dataArray;
    const {clearModal}=this.props;
    const { sideDishdate } = planParams;
    let unix = date.unix(); // so in the products card we will have the time stamp
    var mealPlan = {};
     mealPlan[unix] = customdays[unix][bagName];
    if (!dietproducts) {
      var {products} = dataArray;
  } else {
      var products = dietproducts;
  }
  const dayNum = date.date() - 1;

  const customonChange = (value, checked,is_confirm_dislike=false) => {
    var mealDishAlone = Object.values(customdays[unix])[0];
    let oldDatePlan = { ...customdays[unix] };
    var {dislikeproducts,allergyproducts} = this.state;
    var swalselectVal = 0;
    var vals = {};
    // console.log("======= DEBUG STATEMENT =================");
    // console.table(oldDatePlan);
    // var valt = { ...customdays, [unix]: {...{[bagName]:{...Object.values(customdays[unix])[0],[activeType]: +value}}}};
    // vals = {...Object.values(customdays[unix])[0]};
    // console.table(valt);
    // console.log(value);
    // console.log(unix);
    // console.log("======= DEBUG STATEMENT =================");
    if(allergyproducts.indexOf(value) != -1&&!is_confirm_dislike){
      setModal({ headLine: 'Ingredient as a allergy', message: (
        <div>
        <p>Sorry, due to your allergy preference this dish is not available for selection.</p>
        </div>)
      });
    }else if(dislikeproducts.indexOf(value) != -1&&!is_confirm_dislike){
      /*setModal({ headLine: 'Ingredient as a Dislike', message: (
        <div>
        <p>This dish has an ingredient which is in your dislike list. Please note we would not be able to remove the ingredient, if you choose to opt for this dish.
        If not, we would recommend you to select another dish</p>
        <p align="center">
        <PaperButton onClick={() =>{customonChange(value,checked,true);clearModal();}} className='button-small transition next'>
        <span>SELECT THIS DISH</span>
        </PaperButton>
        </p>
        <p align="center">
        <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
        <span>DO NOT SELECT</span>
        </PaperButton>
        </p>
        </div>)
      });*/
      var disLikemsg = "This dish has an ingredient which is in your dislike list. \n\nPlease note we would not be able to remove the ingredient, if you choose to opt for this dish.\n\nIf not, we would recommend you to select another dish"
      if(window.confirm(disLikemsg)){
        if (checked) {
          const {dishExist,msg} = this.sameDishIsSelected(activeType, value);
        if(dishExist == 2){
          if(window.confirm(msg)){
            if (checked) {
              var modifyOldPlan = function(spy){
                Object.keys(spy).forEach(function(key,ind,arr){
                if(key == bagName){
                spy[key][activeType] = +value;
                }
                });
                return spy;
                }
                modifyOldPlan(oldDatePlan);
                setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
            } else {
              //delete oldDatePlan[activeType];
              setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
            }
          }else{
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          }
        }else{
          if (checked) {
            var modifyOldPlan = function(spy){
              Object.keys(spy).forEach(function(key,ind,arr){
              if(key == bagName){
              spy[key][activeType] = +value;
              }
              });
              return spy;
              }
              modifyOldPlan(oldDatePlan);
              setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          } else {
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          }
        }
        } else {
          //delete oldDatePlan[activeType];
          setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
        }
      }else{
        setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
      }
    }else if(!is_confirm_dislike){
      const {dishExist,msg} = this.sameDishIsSelected(activeType, value);
      if(dishExist == 2){
        if(window.confirm(msg)){
          if (checked) {
            var modifyOldPlan = function(spy){
            Object.keys(spy).forEach(function(key,ind,arr){
            if(key == bagName){
            spy[key][activeType] = +value;
            }
            });
            return spy;
            }
            modifyOldPlan(oldDatePlan);
            setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          } else {
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          }
        }else{
          //delete oldDatePlan[activeType];
          setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
        }
      }else{
        if (checked) {
          var modifyOldPlan = function(spy){
            Object.keys(spy).forEach(function(key,ind,arr){
              if(key == bagName){
                spy[key][activeType] = +value;
              }
            });
            return spy;
          }
          modifyOldPlan(oldDatePlan);
          setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
        }else{
          //delete oldDatePlan[activeType];
          setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
        }
      }
    }

      if(is_confirm_dislike){
        const {dishExist,msg} = this.sameDishIsSelected(activeType, value);
        if(dishExist1 == 2){
          if(window.confirm(msg)){
            if (checked) {
              var modifyOldPlan = function(spy){
                Object.keys(spy).forEach(function(key,ind,arr){
                if(key == bagName){
                spy[key][activeType] = +value;
                }
                });
                return spy;
                }
                modifyOldPlan(oldDatePlan);
                setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
            } else {
              //delete oldDatePlan[activeType];
              setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
            }
          }else{
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          }
        }else{
          if (checked) {
            var modifyOldPlan = function(spy){
              Object.keys(spy).forEach(function(key,ind,arr){
              if(key == bagName){
              spy[key][activeType] = +value;
              }
              });
              return spy;
              }
              modifyOldPlan(oldDatePlan);
              setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          } else {
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customdays, [unix]: {...oldDatePlan}});
          }
        }
      }

    setCustomPlanParams({ ...planParams, modified: true });

  };
  var modifyMealPlan = Object.values(customdays[unix])[0];

  const plan = customPlans.find(el => +el.mealType_id === +activeType) || {};

  let prodKeys = (plan.data || {})[dayNum] || [];
  // if (allergies && dislikes && allergies.length && dislikes.length) {
    //prodKeys = prodKeys.filter(el => intersect(products[el].ingredients, allergies, dislikes).length === 0);
  // }
  if (eliminate_groups && eliminate_groups.length ) {
      prodKeys = prodKeys.filter(el => !eliminate_groups.includes(products[el].group));
  }
  if (dishdislikes && dishdislikes.length ) {
      prodKeys = prodKeys.filter(el => !dishdislikes.includes(el));
  }
  if (preferences && preferences.length) {
    prodKeys = prodKeys.sort((el1, el2) => intersect(products[el2].ingredients, preferences) - intersect(products[el1].ingredients, preferences));
  }
  const list = typeEdit ? prodKeys : Object.keys(mealPlan[unix] || {}).sort((el1, el2) => typeList[el1].order - typeList[el2].order);
  var sidedata=[];
  var scarb=0;
  var scalories=0;
  var sprotein=0;
  var sfat=0;
  let listdata =  list.map((key, idx) => {

    const productId = typeEdit ? key : mealPlan[unix][key];
    const type_id = typeEdit ? activeType : key;
    if(typeList[type_id].parent_id==0){
      const { children:mealTypeChildren } = typeList[type_id];
    const product = (products || {})[productId];
    const checked = typeEdit && mealPlan[unix] && (mealPlan[unix][activeType] === +productId);

    const title = typeEdit ? typeList[activeType].title + ' option #' + (idx + 1) : typeList[key].title;
    var label = false;
    if (dishLabels && typeof(dishLabels[product['label_id']]) != 'undefined') {
      label = dishLabels[product['label_id']];
    }
          /* Start Added the dislike,allergies */
      var allergens = null;
      var dontlike = null;
      var ing = [];
      var dont = [];
      if (product.hasAllergens) {
          allergens = 'Allergies:'
          product.allergens.map( (allergen_id, index) => {
            var hasuser = null;
            if (allergies && allergies.indexOf(allergen_id) != -1) {
              this.updateNotselectableProducts(productId,'allergy');
              ing.push(ingredientList[allergen_id]);
            }

          });
        }

        product.ingredients.map( (ingredient_id, index) => {
          if (dislikes && dislikes.indexOf(ingredient_id) != -1) {
            dontlike = 'Dislikes:'
            dont.push(ingredientList[ingredient_id]);
            this.updateNotselectableProducts(productId,'dislike');
          }else if(allergies && allergies.indexOf(ingredient_id) != -1){
            ing.push(ingredientList[ingredient_id]);
            this.updateNotselectableProducts(productId,'allergy');
          }
        });
     /* End Added the dislike,allergis */


    var fat = product['fat'] / 1000;
    var protein = product['protein'] / 1000;
    var carb = product['carb'] / 1000;
    var calories = product['calories'] / 1000;
    var thetitle = product.title;
    var editsides = false;
    var alternative = false;
    var dietside = false;
    var buysides = false;
    var longblock = false;
    if (product['dishType'] == 'composite'||product['parent_id']!=0) {
      buysides = true;
    }
    var prefix = ' with ';
    var other_sides=[];
    var fixed_sides=[];
    var sideList='';
    var sidesContent=null;

    if(mealTypeChildren!=null && mealPlan[unix][mealTypeChildren.id] != undefined){
        
         const sideDish = !typeEdit?Object.keys(mealPlan[unix][mealTypeChildren.id]).map((sideid)=>{
             var sideid=mealPlan[unix][mealTypeChildren.id][sideid];
             if(products[sideid]['title']!='No side'){
             carb += products[sideid]['carb'] / 1000;
             calories += products[sideid]['calories'] / 1000;
             protein += products[sideid]['protein'] / 1000;
             fat += products[sideid]['fat'] / 1000;
             }
             return <h2 className="product-title side-title side-title transition">{products[sideid]['title']}</h2>;
       }):[];
       sideList=<div className="side-title-cont">{sideDish}</div>;
       sidesContent = typeEdit && idx==0 ? Object.keys(mealPlan[unix][mealTypeChildren.id]).map((sideId, idx) => {
        const plan = customPlanSides.find(el => +el.mealType_id === mealTypeChildren.id && +el.side_type_id == sideId) || {};
        let prodKeys1 = (plan.data || {})[dayNum] || [];
        const dishId=mealPlan[unix][mealTypeChildren.id][sideId];
          const { title, image, calories, protein,carb,fat } = products[dishId] || {};
          const imgUrl = image ? `${image}` : noImage;
          if(title != 'No side'){
          scalories+= calories /1000;
          scarb+= carb/1000;
          sprotein+= protein/1000;
          sfat+= fat/1000;
          }
         return (
           <div className='sidedish' key={idx}>

             <div className='sidedish-content'>
             <p className='image' ><img src={imgUrl} />
              <div class="image-caption-holder">
              <p className='title'>{title}</p>
              {title != 'No side'&&<p className="skcal">
               <span className="card-kacal">{`Kcal ${(calories/1000)}`}</span>
               
               <span>
                   {`PRO ${protein/1000}g`}
                 </span>
               <span>
                   {`CHO ${carb/1000}g`}
                 </span>
                 <span>
                   {`F ${fat/1000}g`}
                 </span>
               </p>}
               <p className="link" onClick={() =>this.showSideModal(unix,prodKeys1, dishId, sideId,mealTypeChildren.id)}>Change</p>
              </div>
             </p>


             </div>
           </div>
         );

     }) : null;

   }

    if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
      if (typeof(usersides[unix][productId]) != 'undefined') {
          var theproduct = products[productId];
          fat = theproduct.fat / 1000;
          protein = theproduct.protein / 1000;
          carb = theproduct.carb / 1000;
          calories = theproduct.calories / 1000;
          thetitle = theproduct.title;

          {typeof(usersides[unix][productId][bagName]) != 'undefined'&&Object.keys(usersides[unix][productId][bagName][type_id]).map( (el, idx) => {
            var side = usersides[unix][productId][bagName][type_id][el];
            if (typeof(products[side.side_id]) != 'undefined') {
              editsides = true;

              // if (!dietside && planParams.diet == 4 && side.category == 2) {
              //   // for keto and oils:
              //   fat += products[side.side_id].fat / 1000;
              //   protein += products[side.side_id].protein / 1000;
              //   carb += products[side.side_id].carb / 1000;
              //   calories += products[side.side_id].calories / 1000;
              //   // thetitle = thetitle + prefix + products[side.side_id].title;
              //   prefix = ' & ';
              //   dietside = true;
              // }

              // if (!alternative || side.type != 'alternative') {
              //   if (!(dietside && side.category == 2)) {
              //     fat += products[side.side_id].fat / 1000;
              //     protein += products[side.side_id].protein / 1000;
              //     carb += products[side.side_id].carb / 1000;
              //     calories += products[side.side_id].calories / 1000;
              //     // thetitle = thetitle + prefix + products[side.side_id].title;
              //     prefix = ' & ';
              //     if (side.type == 'alternative') {
              //       alternative = true;
              //     }
              //   }
              // }
              alternative = true;
              // longblock = true;
              fat += products[side.side_id].fat / 1000;
              protein += products[side.side_id].protein / 1000;
              carb += products[side.side_id].carb / 1000;
              calories += products[side.side_id].calories / 1000;
              if(side.type == 'alternative'){
                other_sides.push( products[side.side_id].title);
              }
              if(side.type == 'addons'){
                fixed_sides.push(products[side.side_id].title);
              }
            }
        });}
      }
    }
    if (!alternative&&product['mealTypeSides'][type_id]!=undefined) {
        var side_id = product['mealTypeSides'][type_id];
        fat += products[side_id].fat / 1000;
        protein += products[side_id].protein / 1000;
        carb += products[side_id].carb / 1000;
        calories += products[side_id].calories / 1000;
        // thetitle = thetitle + prefix + products[side_id].title;
        // thetitle = thetitle + prefix + products[side_id].title;
        other_sides.push(products[side_id].title);
        prefix = ' & ';
        editsides = true;
        // longblock = true;
        // Object.keys(product['all_sides']).map( (el, idx) => {
        //   var side_id = product['all_sides'][el];
        //   if (typeof(products[side_id]) != 'undefined') {
        //       fat += products[side_id].fat / 1000;
        //       protein += products[side_id].protein / 1000;
        //       carb += products[side_id].carb / 1000;
        //       calories += products[side_id].calories / 1000;
        //       // thetitle = thetitle + prefix + products[side_id].title;
        //       // thetitle = thetitle + prefix + products[side_id].title;
        //       // fixed_sides=products[side_id].title;
        //       prefix = ' & ';
        //       editsides = true;
        //       longblock = true;
        //   }
        // });
    }

    var showit = false;

    Object.keys(showinfo).map( (el, idx) => {
      if (showinfo[el] == title) {
        showit = true;
      }
    });

    // if (longblock && !showit) {
    //   thetitle = product.title + ' with sides';
    // }
    const renderslide=(datas)=>{
      if(datas&&datas.length>=1){
        const newval=datas.map((oel)=>{ return <small>{oel}</small>;})
        return newval;
      }
    }
    if(typeEdit && idx==0&&sidesContent!=null){
      sidedata.push(<div className="products sidedishcon" ><h3>Side Dishes</h3>{sidesContent}</div>);
    }
    let emoji=CardEmojiIcon;
    let emojiClass='norate';
      if(ratings[productId]!=undefined){
        switch(ratings[productId]){
            case 5:
              emoji=LoveEmojiIcon;
              emojiClass='rateok';
            break;
            case 4:
              emoji=GoodEmojiIcon;
              emojiClass='rateok';
            break;
            case 3:
              emoji=OkEmojiIcon;
              emojiClass='rateok';
            break;
            case 2:
              emoji=BadEmojiIcon;
              emojiClass='rateok';
            break;
        }
      }
    var totalkcal=scalories+calories;
    return product ? (
      <div className='products' key={idx} onWheel={e => e.stopPropagation()}>
        <div className={`product transition d-card-inr-holder ${checked ? 'chosen' : 'not'} ${(ing.length>0)?'light-fade':''}`}>
        <span class="selected_dish"><i class="fa fa-check-circle" aria-hidden="true"></i>Selected</span>
        <div className='img-holder d-card-img'>
          {/*  onClick={() =>{ !typeEdit && goMenu('showDescription', +productId)}} */}
          <div className={`image_click ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && customonChange(productId, !checked); }}>
              <img src={product.preview || noImage} />
          </div>    
          <div class="prod-rat-ics">
          <button className={`d-like-btn ${Array.isArray(dishlikes)&&dishlikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'like')}> <i></i><span>liked!</span></button>
          <button className={`d-emoji-display ${Array.isArray(dishdislikes)&&dishdislikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'dislike')}><i></i></button>
<a onClick={()=>{this.rateDishModal(productId)}} className="rate-emoji"><img className={`${emojiClass}`} src={`${emoji}`} /></a>
          </div>
          <h3 className={` ${typeEdit && checked ? 'gold' : ''}`}>{checked ? 'Selected option' : title}</h3>
          {typeEdit && <div className="edit-label">
            <h2 className='product-title transition'>{thetitle}</h2>
            {label &&<span className="dish-label-value l-green-label">{label}</span>}
            </div>}
        </div>
          <div className={`inner d-card-caption ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && customonChange(productId, !checked); }}>

            {/* <div className='stars'>
              <p>
                <Rating onChange={(value) => this.saveRating(productId, value)}
                  initialRating={ratings[productId] || 0}
                  emptySymbol={<span className='icon-star mx-1 letter-normal light-dark-text' />}
                  fullSymbol={<span className='icon-star mx-1 letter-normal green fullStar' />}
                />
              </p>
            </div> */}

            {!typeEdit && <h2 onClick={() => { !typeEdit && goMenu('showDescription', +productId)}} className='product-title transition'>{thetitle}</h2>}
            {!typeEdit && label && <span className="dish-label-value l-green-label">{label}</span>}
            {!typeEdit && sideList}
            {(!typeEdit && !sideList && sideDishdate.includes(unix.toString()))&&<div class="leaf-image"><img src={`${Leaf}`} /></div>}
            {editsides&&<div className="dc-caption-row d-flex justify-content-between mt-3 mb-3 align-items-end">
                 <div className="dc-caption-row-lt"><p className="mb-0"><strong>Side</strong></p>
                {renderslide(other_sides)}
                </div>
                {!buffered&&<div className="dc-caption-row-rt"><span
                    onClick={e => {
                        this.sidesModal(unix, productId, key,'InitialBag');
                    }}
                    className='btn-label-dgreenoutline btn-sm'>
                  Change Sides</span></div>}
              </div>}

              {buysides&&fixed_sides.length>0&&<p className="mt-3 mb-0"><strong>Fixed Side</strong></p>}
              {buysides&&fixed_sides.length>0&&<div className="dc-caption-row d-flex justify-content-between mt-0 mb-3 align-items-end">
                <div className="dc-caption-row-lt">
                {renderslide(fixed_sides)}
                </div>
                {!buffered&&<div className="dc-caption-row-rt"><span onClick={ e => {
                    var thetype = null;
                    Object.keys(mealPlan[unix]).map( (el, idx) => {
                      if (mealPlan[unix][el] == productId) {
                        thetype = el;
                      }
                    });
                    // console.log(thetype);
                    this.buySidesModal(unix, productId, thetype,'InitialBag');
                  }} className='editsides-btn btn-label-dgreen btn-sm'>Change / Add Side</span></div>}
              </div>}
              { (!longblock || showit) && <div>
                <div className='main-dish'>{ typeEdit && sidedata.length>0 && <p class="sbold">Main Dish</p>}
                <div className="d-kal-info mb-3">
              <span onClick={() =>{ !typeEdit && goMenu('showDescription', +productId)}} className='card-kacal'><i><img src={caloriesIcon} /></i>{`Kcal ${calories}`}</span>
              <span>
                  {`PRO ${protein} g`}
                </span>
              <span>
                  {`CHO ${carb} g`}
                </span>
                <span>
                  {`F ${fat} g`}
                </span>
              </div></div>
              { typeEdit && sidedata.length>0 && <div className='side-dish-kcal'><p><b class="sbold">Including Sides:</b></p> <div className="d-kal-info mb-3">

               <span className='card-kacal'><i><img src={caloriesIcon} /></i>{`Kcal ${totalkcal}`}</span>
               <span>
                   {`PRO ${sprotein+protein}g`}
                 </span>
               <span>
                   {`CHO ${scarb+carb}g`}
                 </span>
                 <span>
                   {`F ${sfat+fat}g`}
                 </span>
               </div></div>}
              {ing.length>0&&<div className="dc-alergy-holder d-flex">
                      <p className='red'> Allergy : <span>{(ing.length>1)?ing.join(','):ing}</span></p>
                    </div>}
              {dont.length>0&&<div className="dc-dislike-holder d-flex">
                <p className='orange'> Dislike : <span>{(dont.length>1)?dont.join(','):dont}</span></p>
              </div>}   
              <span className='spicy'>{product['spicy'] ? 'Spicy' : ''}</span>           
              <div className="d-flex discrd-ftr">
                <div className="discrd-ftr-lt">
                {/* <button className='btn-label-turq-outline d-v-more-btn' onClick={() => goMenu('showDescription', +productId)}>View details</button> */}
                <button className='btn-label-turq-outline d-v-more-btn' onClick={() => this.showDishDetails(+productId)}>View details</button>
                </div>
             </div>
            
            </div>}
          {longblock && !showit && <span className="show-info" onClick={() => {
            showinfo.push(title);
            this.setState({
              showinfo: showinfo
            })
          }}>Show Full Information</span>}
          </div>

        </div>
      </div>
    ) : null;
        }
  });
  var filtered = listdata.filter(function (el) {
    return el != undefined ;
  });
  // if(sidedata.length>=1){
  //   filtered.unshift(sidedata);
  // }
  return filtered;
   }
   showSideModal = (currentDate, prodKeys, dishId, sideId, currentType) => {
    const {setModal,dataArray,clearModal } = this.props;
    const { products }=dataArray;
    var sides={};
     console.log('sdsd');
     console.log(currentDate);
     console.log(prodKeys);
    var selected={};
    const sidelist =prodKeys.map((dishId1, idx) => {
      const { title, fat, protein, carb, calories } = products[dishId1] || {};
        let rObj = {}
        rObj[dishId1] = title;
        sides[dishId1] = title + ' (fat: '+fat/1000+', protein '+protein/1000+', carbs '+carb/1000+', calories '+calories/1000+')';
        if(dishId==dishId1){
          selected={value:dishId1,label:sides[dishId1]};

        }
      return rObj;
    });

    console.log(sides);
    setModal({ headLine: 'Change your sides', message:
      (
        <div>
            <SideDishModal sidelist={sides} currentType={currentType} currentDate={currentDate} sideId={sideId} dishId={selected} changeMealside={this.changePlan} />
            <p align="center">
            <PaperButton onClick={() => clearModal()} className='button-small transition next'>
                    <span>Done</span>
            </PaperButton></p>
        </div>
      )
    });
  }
  changePlan = (currentDate, currentType, dishId,sideId='') => {
    const { mealPlan, setMealPlan } = this.props;
    setMealPlan({ ...mealPlan, [currentDate]: { ...mealPlan[currentDate], [currentType]:{  ...mealPlan[currentDate][currentType], [sideId]: + dishId } } });
  };
  renderSidesProduct = () =>{
    const { activeType } = this.state;
    const { customPlanSides, mealPlan, date, dataArray, dietproducts } = this.props;
    const { typeList } = dataArray;
    let unix = date.unix(); // so in the products card we will have the time stamp
    const { children:mealTypeChildren } = typeList[activeType];
    const dayNum = date.date() - 1;
      return (mealPlan[unix][mealTypeChildren.id]!=undefined) && Object.keys(mealPlan[unix][mealTypeChildren.id]).map((sideId, idx) => {
            const plan = customPlanSides.find(el => +el.mealType_id === mealTypeChildren.id && +el.side_type_id == sideId) || {};
            let prodKeys1 = (plan.data || {})[dayNum] || [];
            const dishId=mealPlan[unix][mealTypeChildren.id][sideId];
            const { title, image, calories, protein,carb,fat } = dietproducts[dishId] || {};
            const imgUrl = image ? `${image}` : noImage;
          return (
            <div className='sidedish' key={idx}>

             <div className='sidedish-content'>
             <p className='image' ><img src={imgUrl} />
<div class="image-caption-holder">
<p className='title'>{title}</p>
{title != 'No side' && <p>
 <span>{`Kcal ${(calories/1000)}`}</span>
 <span>
     {`PRO ${protein/1000}g`}
   </span>
 <span>
     {`CHO ${carb/1000}g`}
   </span>
   <span>
     {`F ${fat/1000}g`}
   </span>
 </p>}
<p className="link" onClick={() =>this.showSideModal(unix,prodKeys1, dishId, sideId,mealTypeChildren.id)}>Change</p>
</div>
             </p>
             </div>
           </div>
          );

        });
  }

  renderProduct = (buffered) => {
    const { activeType, typeEdit, showinfo } = this.state;
    const { goMenu, date, setMealPlan, setPlanParams, planParams, dataArray, customPlans,customPlanSides, ratings, dietproducts, custom, customdays, usersides, setModal, user} = this.props;
    const { preferences, allergies, dislikes,eliminate_groups, dishlikes, dishdislikes } = user;
    const { typeList, dishLabels,ingredientList } = dataArray;
    const { sideDishdate } = planParams;
    const {clearModal}=this.props;
    var {mealPlan} = this.props;

    let unix = date.unix(); // so in the products card we will have the time stamp

    if (!dietproducts) {
        var {products} = dataArray;
    } else {
        var products = dietproducts;
    }

    const dayNum = date.date() - 1;
    const onChange = (value, checked, is_confirm_dislike) => {
      let oldDatePlan = { ...mealPlan[unix] };
      var {dislikeproducts,allergyproducts} = this.state;

      if(allergyproducts.indexOf(value) != -1&&!is_confirm_dislike){
        setModal({ headLine: 'Ingredient as a allergy', message: (
          <div>
            <p>Sorry, due to your allergy preference this dish is not available for selection.</p>
          </div>)
        });
     }else if(dislikeproducts.indexOf(value) != -1 && !is_confirm_dislike){
      setModal({ headLine: 'Ingredient as a Dislike', message: (
        <div>
          <p>This dish has an ingredient which is in your dislike list. Please note we would not be able to remove the ingredient, if you choose to opt for this dish.
If not, we would recommend you to select another dish</p>
      <p align="center">
                <PaperButton onClick={() =>{onChange(value,checked,true);clearModal();}} className='button-small transition next'>
                  <span>SELECT THIS DISH</span>
                </PaperButton>
                </p>
         <p align="center">
                <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                  <span>DO NOT SELECT</span>
                </PaperButton>
                </p>
        </div>)
      });
    }else if(!is_confirm_dislike){
      const {dishExist,msg} = this.sameDishIsSelected(activeType, value);
      if(dishExist == 2){

        if(window.confirm(msg)){
          if (checked) {
            //this.sameDishIsSelected(activeType, value);
            setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
          } else {
            delete oldDatePlan[activeType];
            setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
          }
        }else{
          //delete oldDatePlan[activeType];
            setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
        }
      }else{
        if (checked) {
          //this.sameDishIsSelected(activeType, value);
          setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
        } else {
          delete oldDatePlan[activeType];
          setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
        }
      }
    }

      if(is_confirm_dislike){
        const {dishExist,msg} = this.sameDishIsSelected(activeType, value);
        if(dishExist == 2){
          if(window.confirm(msg)){
            if (checked) {
              //this.sameDishIsSelected(activeType, value);
              setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
            } else {
              delete oldDatePlan[activeType];
              setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
            }
          }else{
            //delete oldDatePlan[activeType];
              setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
          }
        }else{
          if (checked) {
            //this.sameDishIsSelected(activeType, value);
            setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
          } else {
            delete oldDatePlan[activeType];
            setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
          }
        }
      }

       setPlanParams({ ...planParams, modified: true });
    };
    const plan = customPlans.find(el => +el.mealType_id === +activeType) || {};

    let prodKeys = (plan.data || {})[dayNum] || [];
    // if (allergies && dislikes && allergies.length && dislikes.length) {
      //prodKeys = prodKeys.filter(el => intersect(products[el].ingredients, allergies, dislikes).length === 0);
    // }
    if (eliminate_groups && eliminate_groups.length ) {
        prodKeys = prodKeys.filter(el => !eliminate_groups.includes(products[el].group));
    }
    if (preferences && preferences.length) {
      prodKeys = prodKeys.sort((el1, el2) => intersect(products[el2].ingredients, preferences) - intersect(products[el1].ingredients, preferences));
    }
    const list = typeEdit ? prodKeys : Object.keys(mealPlan[unix] || {}).sort((el1, el2) => typeList[el1].order - typeList[el2].order);

    var sidedata=[];
    var scarb=0;
    var scalories=0;
    var sprotein=0;
    var sfat=0;
    let listdata =  list.map((key, idx) => {
      const productId = typeEdit ? key : mealPlan[unix][key];
      const type_id = typeEdit ? activeType : key;
      if(typeList[type_id].parent_id==0){
        const { children:mealTypeChildren } = typeList[type_id];
      const product = (products || {})[productId];
      const checked = typeEdit && mealPlan[unix] && (mealPlan[unix][activeType] === +productId);
      const title = typeEdit ? typeList[activeType].title + ' option #' + (idx + 1) : typeList[key].title;
      var label = false;
      if (dishLabels && typeof(dishLabels[product['label_id']]) != 'undefined') {
        label = dishLabels[product['label_id']];
      }

            /* Start Added the dislike,allergies */
            var allergens = null;
            var dontlike = null;
            var ing = [];
            var dont = [];
            if (product.hasAllergens) {
                allergens = 'Allergies:'
                product.allergens.map( (allergen_id, index) => {
                  var hasuser = null;
                  if (allergies && allergies.indexOf(allergen_id) != -1) {
                    this.updateNotselectableProducts(productId,'allergy');
                    ing.push(ingredientList[allergen_id]);
                  }

                });
              }

              product.ingredients.map( (ingredient_id, index) => {
                if (dislikes && dislikes.indexOf(ingredient_id) != -1) {
                  dontlike = 'Dislikes:'
                  dont.push(ingredientList[ingredient_id]);
                  this.updateNotselectableProducts(productId,'dislike');
                }else if(allergies && allergies.indexOf(ingredient_id) != -1){
                  ing.push(ingredientList[ingredient_id]);
                  this.updateNotselectableProducts(productId,'allergy');
                }
              });
           /* End Added the dislike,allergis */


      var fat = product['fat'] / 1000;
      var protein = product['protein'] / 1000;
      var carb = product['carb'] / 1000;
      var calories = product['calories'] / 1000;
      var thetitle = product.title;
      var editsides = false;
      var alternative = false;
      var dietside = false;
      var buysides = false;
      var longblock = false;
      if (product['dishType'] == 'composite'||product['parent_id']!=0) {
        buysides = true;
      }
      var prefix = ' with ';
      var other_sides=[];
      var fixed_sides=[];
      var sideList='';
      var sidesContent=null;

      if(mealTypeChildren!=null && mealPlan[unix][mealTypeChildren.id] != undefined){

           const sideDish = !typeEdit?Object.keys(mealPlan[unix][mealTypeChildren.id]).map((sideid)=>{
               var sideid=mealPlan[unix][mealTypeChildren.id][sideid];
               if(dietproducts[sideid]['title']!='No side'){
               carb += dietproducts[sideid]['carb'] / 1000;
               calories += dietproducts[sideid]['calories'] / 1000;
               protein += dietproducts[sideid]['protein'] / 1000;
               fat += dietproducts[sideid]['fat'] / 1000;
              }
               return <h2 className="product-title transition">{dietproducts[sideid]['title']}</h2>;
         }):[];
         sideList=<div className="side-title-cont">{sideDish}</div>;
         sidesContent = typeEdit && idx==0 ? Object.keys(mealPlan[unix][mealTypeChildren.id]).map((sideId, idx) => {
          const plan = customPlanSides.find(el => +el.mealType_id === mealTypeChildren.id && +el.side_type_id == sideId) || {};
          let prodKeys1 = (plan.data || {})[dayNum] || [];
          const dishId=mealPlan[unix][mealTypeChildren.id][sideId];
          const { title, image, calories, protein,carb,fat } = dietproducts[dishId] || {};
          const imgUrl = image ? `${image}` : noImage;
          if(title != 'No side'){
          scalories+= calories /1000;
          scarb+= carb/1000;
          sprotein+= protein/1000;
          sfat+= fat/1000;
          }
         return (
           <div className='sidedish' key={idx}>

             <div className='sidedish-content'>
             <p className='image' ><img src={imgUrl} />
<div class="image-caption-holder">
<p className='title'>{title}</p>
{title != 'No side' && <p>
 <span>{`Kcal ${(calories/1000)}`}</span>
 <span>
     {`PRO ${protein/1000}g`}
   </span>
 <span>
     {`CHO ${carb/1000}g`}
   </span>
   <span>
     {`F ${fat/1000}g`}
   </span>
 </p>}
<p className="link" onClick={() =>this.showSideModal(unix,prodKeys1, dishId, sideId,mealTypeChildren.id)}>Change</p>
</div>
             </p>
             </div>
           </div>
         );

       }) : null;

     }
      if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
        if (typeof(usersides[unix][productId]) != 'undefined') {
          var theproduct = products[productId];
          fat = theproduct.fat / 1000;
          protein = theproduct.protein / 1000;
          carb = theproduct.carb / 1000;
          calories = theproduct.calories / 1000;
          // thetitle = theproduct.title;
        //  console.log(usersides[unix][productId]);
        {usersides[unix][productId]['InitialBag']!= 'undefined'&&usersides[unix][productId]['InitialBag'][type_id]!=undefined&&Object.keys(usersides[unix][productId]['InitialBag'][type_id]).map( (el, idx) => {
              var side = usersides[unix][productId]['InitialBag'][type_id][el];
              if (typeof(products[side.side_id]) != 'undefined') {
                editsides = true;
                alternative = true;
                // longblock = true;
                fat += products[side.side_id].fat / 1000;
                protein += products[side.side_id].protein / 1000;
                carb += products[side.side_id].carb / 1000;
                calories += products[side.side_id].calories / 1000;
                if(side.type == 'alternative'){
                  other_sides.push( products[side.side_id].title);
                }
                if(side.type == 'addons'){
                  fixed_sides.push(products[side.side_id].title);
                }
              }
          });}
        }
      }

      if (!alternative&&product['mealTypeSides'][type_id]!=undefined) {
        var side_id = product['mealTypeSides'][type_id];
        fat += products[side_id].fat / 1000;
        protein += products[side_id].protein / 1000;
        carb += products[side_id].carb / 1000;
        calories += products[side_id].calories / 1000;
        other_sides.push(products[side_id].title);
        prefix = ' & ';
        editsides = true;
      
    }

      var showit = false;

      Object.keys(showinfo).map( (el, idx) => {
        if (showinfo[el] == title) {
          showit = true;
        }
      });

      // if (longblock && !showit) {
      //   thetitle = product.title + ' with sides';
      // }
      const renderslide=(datas)=>{
        if(datas&&datas.length>=1){
          const newval=datas.map((oel)=>{ return <small>{oel}</small>;})
          return newval;
        }
      }
      if(typeEdit && idx==0&&sidesContent!=null){
        sidedata.push(<div className="products sidedishcon" ><h3>Side Dishes</h3>{sidesContent}</div>);
      }
      let emoji=CardEmojiIcon;
      let emojiClass='norate';
      if(ratings[productId]!=undefined){
        switch(ratings[productId]){
            case 5:
              emoji=LoveEmojiIcon;
              emojiClass='rateok';
            break;
            case 4:
              emoji=GoodEmojiIcon;
              emojiClass='rateok';
            break;
            case 3:
              emoji=OkEmojiIcon;
              emojiClass='rateok';
            break;
            case 2:
              emoji=BadEmojiIcon;
              emojiClass='rateok';
            break;
        }
      }
      var totalkcal=scalories+calories;
      return product ? (
        <div className='products' key={idx} onWheel={e => e.stopPropagation()}>
          <div className={`product transition d-card-inr-holder ${checked ? 'chosen' : 'not'} ${(ing.length>0)?'light-fade':''}`}>
          <span class="selected_dish"><i class="fa fa-check-circle" aria-hidden="true"></i>Selected</span>
          <div className='img-holder d-card-img'>
            {/*  onClick={() =>{ !typeEdit && goMenu('showDescription', +productId)}} */}
            <div className={`image_click ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && onChange(productId, !checked); }}>
              <img src={product.preview || noImage} />
            </div>    
            <div class="prod-rat-ics">
              <button className={`d-like-btn ${Array.isArray(dishlikes)&&dishlikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'like')}> <i></i><span>liked!</span></button>
              <button className={`d-emoji-display ${Array.isArray(dishdislikes)&&dishdislikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'dislike')}><i></i></button>
    <a onClick={()=>{this.rateDishModal(productId)}} className="rate-emoji"><img className={`${emojiClass}`} src={`${emoji}`} /></a>
            </div>
            <h3 className={` ${typeEdit && checked ? 'gold' : ''}`}>{checked ? 'Selected option' : title}</h3>
            {typeEdit && <div className="edit-label">
              <h2 className='product-title transition'>{thetitle}</h2>
              {label && <span className="dish-label-value l-green-label">{label}</span>}
              </div>}
        </div>
            <div className={`inner d-card-caption ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && onChange(productId, !checked,false); }}>

              {/* <div className='stars'>
                <p>
                  <Rating onChange={(value) => this.saveRating(productId, value)}
                    initialRating={ratings[productId] || 0}
                    emptySymbol={<span className='icon-star mx-1 letter-normal light-dark-text' />}
                    fullSymbol={<span className='icon-star mx-1 letter-normal green fullStar' />}
                  />
                </p>
              </div> */}

              {!typeEdit && <h2 onClick={() => { !typeEdit && goMenu('showDescription', +productId)}} className='product-title transition'>{thetitle}</h2>}
              {!typeEdit && label && <span className="dish-label-value l-green-label">{label}</span>}
              {!typeEdit && sideList}
              {(!typeEdit && !sideList && sideDishdate.includes(unix.toString()))&&<div class="leaf-image"><img src={`${Leaf}`} /></div>}
              {editsides&&<div className="dc-caption-row d-flex justify-content-between mt-3 mb-3 align-items-end">
                 <div className="dc-caption-row-lt"><p className="mb-0"><strong>Side</strong></p>
                {renderslide(other_sides)}
                </div>
                {!buffered&&<div className="dc-caption-row-rt"><span
                    onClick={e => {
                        this.sidesModal(unix, productId, key,'InitialBag');
                    }}
                    className='btn-label-dgreenoutline btn-sm'>
                  Change Sides</span></div>}
              </div>}

              {buysides&&fixed_sides.length>0&&<p className="mt-3 mb-0"><strong>Fixed Side</strong></p>}
              {buysides&&fixed_sides.length>0&&<div className="dc-caption-row d-flex justify-content-between mt-0 mb-3 align-items-end">
                <div className="dc-caption-row-lt">
                {renderslide(fixed_sides)}
                </div>
                {!buffered&&<div className="dc-caption-row-rt"><span onClick={ e => {
                    var thetype = null;
                    Object.keys(mealPlan[unix]).map( (el, idx) => {
                      if (mealPlan[unix][el] == productId) {
                        thetype = el;
                      }
                    });
                    // console.log(thetype);
                    this.buySidesModal(unix, productId, thetype,'InitialBag');
                  }} className='editsides-btn btn-label-dgreen btn-sm'>Change / Add Side</span></div>}
              </div>}
              { (!longblock || showit) && <div>

                <div className='main-dish'>{ typeEdit && sidedata.length>0 && <p class="sbold">Main Dish</p>}<div className="d-kal-info mb-3">
              <span onClick={() => { !typeEdit && goMenu('showDescription', +productId)}} className='card-kacal'><i><img src={caloriesIcon} /></i>{`Kcal ${calories}`}</span>
              <span>
                  {`PRO ${protein}g`}
                </span>
              <span>
                  {`CHO ${carb}g`}
                </span>
                <span>
                  {`F ${fat}g`}
                </span>
              </div></div>
              { typeEdit && sidedata.length>0 && <div className='side-dish-kcal'><p><b class="sbold">Including Sides:</b></p><div className="d-kal-info mb-3">

               <span className='card-kacal'><i><img src={caloriesIcon} /></i>{`Kcal ${totalkcal}`}</span>
               <span>
                   {`PRO ${sprotein+protein}g`}
                 </span>
               <span>
                   {`CHO ${scarb+carb}g`}
                 </span>
                 <span>
                   {`F ${sfat+fat}g`}
                 </span>
               </div></div>}
              {ing.length>0&&<div className="dc-alergy-holder d-flex">
                      <p className='red'> Allergy : <span>{(ing.length>1)?ing.join(','):ing}</span></p>
                    </div>}
              {dont.length>0&&<div className="dc-dislike-holder d-flex">
                <p className='orange'> Dislike : <span>{(dont.length>1)?dont.join(','):dont}</span></p>
              </div>}        
              <span className='spicy'>{product['spicy'] ? 'Spicy' : ''}</span>      
              <div className="d-flex discrd-ftr">
                <div className="discrd-ftr-lt">
                {/* <button className='btn-label-turq-outline d-v-more-btn' onClick={() => goMenu('showDescription', +productId)}>View details</button> */}
                <button className='btn-label-turq-outline d-v-more-btn' onClick={() => this.showDishDetails(+productId)}>View details</button>
                </div>
             </div>
            
            </div>}
            {longblock && !showit && <span className="show-info" onClick={() => {
              showinfo.push(title);
              console.log(showinfo);
              this.setState({
                showinfo: showinfo
              })
            }}>Show Full Information</span>}
            </div>

          </div>
        </div>
      ) : null;
          }
    });
    var filtered = listdata.filter(function (el) {
      return el != undefined ;
    });
    // if(sidedata.length>=1){
    //   filtered.unshift(sidedata);
    // }
    return filtered;
  };
  renderCarousel =(breakpoints,slidesPerPage=4,slides)=>{
      return <Carousel
      arrowLeft={<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>}
      arrowRight={<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>}
      addArrowClickHandler
      onChange={this.onSliderChange}
      slidesPerPage={slidesPerPage}
      slidesPerScroll={1}
      slides={slides}
      breakpoints={breakpoints}
      />;
  }
  render() {
    const { date, inactive, skipped, changeSkipped, skipTheDay, originTo, custom, dietproducts, customdays, usersides, dataArray} = this.props;
    const { xyz,typeEdit,activeType } = this.state; //calories, fat, protein, cho
    const unix = date.unix();
    const isSkipped = unix in skipped;
    const today = moment().startOf('day');
    const buffered = date.isBetween(today, today.clone().add(3, 'day'), '[)');
    const isdayactive=(buffered||inactive);
    console.log("****************** BUFFERED START ********************");
    console.log(unix);
    console.log(today);
    console.log(buffered);
    console.log(isdayactive);
    console.log("******************** BUFFERED END ***********************");
    var {mealPlan} = this.props;
    const {products , typeList} = dataArray;

    if (custom) {
        mealPlan = customdays;
    }
      var sum_calories = 0;
      var sum_protein = 0;
      var sum_carbs = 0;
      var sum_fat = 0;
      const activetypeList = {...(typeList[activeType] || null )['children'] || null};
      var breakpoints={
        1400: {
          slidesPerPage: 4,
          slidesPerScroll:1
        },
        992: { // these props will be applied when screen width is less than 1000px
          slidesPerPage: (!typeEdit ||  (typeEdit && Object.keys(activetypeList).length<=0)) ? 3 : 2,
          slidesPerScroll:1,
        },
        479: {
          slidesPerPage: 1,
          slidesPerScroll:1,
        },
    };
      if (typeof(mealPlan[unix]) != 'undefined') {
         if(!custom){
            Object.keys(mealPlan[unix]).map( (el, idx) => {
              var product_id = mealPlan[unix][el];
              if(typeList[el].parent_id==0){
                const { children:mealTypeChildren } = typeList[el];

                var calories = dietproducts[product_id].calories/1000;
                var carb = dietproducts[product_id].carb/1000;
                var protein = dietproducts[product_id].protein/1000;
                var fat = dietproducts[product_id].fat/1000;
                if(mealTypeChildren!=null && mealPlan[unix][mealTypeChildren.id] != undefined){
                     const sideDish = Object.keys(mealPlan[unix][mealTypeChildren.id]).map((sideid)=>{
                          var sideid=mealPlan[unix][mealTypeChildren.id][sideid];
                          if(dietproducts[sideid]['title']!='No side'){
                            carb += dietproducts[sideid]['carb'] / 1000;
                            calories += dietproducts[sideid]['calories'] / 1000;
                            protein += dietproducts[sideid]['protein'] / 1000;
                            fat += dietproducts[sideid]['fat'] / 1000;
                          }
                          return <h5>{dietproducts[sideid]['title']}</h5>;
                   })

                }

              if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
                if (typeof(usersides[unix][product_id]) != 'undefined') {
                  {typeof(usersides[unix][product_id]['InitialBag']) != 'undefined'&&usersides[unix][product_id]['InitialBag'][el] != undefined &&Object.keys(usersides[unix][product_id]['InitialBag'][el]).map( (side1, idx1) => {
                    var side = usersides[unix][product_id]['InitialBag'][el][side1];
                    if (typeof(products[side.side_id]) != 'undefined') {
                        fat += products[side.side_id].fat / 1000;
                        protein += products[side.side_id].protein / 1000;
                        carb += products[side.side_id].carb / 1000;
                        calories += products[side.side_id].calories / 1000;
                      }
                  });}
                }
              }

              sum_calories = sum_calories + calories;
              sum_protein = sum_protein + protein;
              sum_carbs = sum_carbs + carb;
              sum_fat = sum_fat + fat;
            }
            });
          }else if(custom){
            // need to work
          }
      }

    return (
      <div>
      {custom && (
        Object.keys(mealPlan[unix]).map( (el, idx) => {
          var sum_calories = 0;
          var sum_protein = 0;
          var sum_carbs = 0;
          var sum_fat = 0;
          var bagCount = 0;
          Object.keys(mealPlan[unix][el]).map((el1, idxl) => {
            const { children:mealTypeChildren,parent_id } = typeList[el1];
            if(parent_id==0){
            var product_id = mealPlan[unix][el][el1];
            var calories = dietproducts[product_id].calories/1000;
            var carb = dietproducts[product_id].carb/1000;
            var protein = dietproducts[product_id].protein/1000;
            var fat = dietproducts[product_id].fat/1000;
            if(mealTypeChildren!=null &&mealPlan[unix][el][mealTypeChildren.id] != undefined){
              Object.keys(mealPlan[unix][el][mealTypeChildren.id]).map((sideid)=>{
                var sideid=mealPlan[unix][el][mealTypeChildren.id][sideid];
                if(dietproducts[sideid]['title']!='No side'){
                  carb += dietproducts[sideid]['carb'] / 1000;
                  calories += dietproducts[sideid]['calories'] / 1000;
                  protein += dietproducts[sideid]['protein'] / 1000;
                  fat += dietproducts[sideid]['fat'] / 1000;
                }

              })
            }
            if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
              if (typeof(usersides[unix][product_id]) != 'undefined') {
                {typeof(usersides[unix][product_id][el]) != 'undefined'&&Object.keys(usersides[unix][product_id][el][el1]).map( (side1, idx1) => {
                  var side = usersides[unix][product_id][el][el1][side1];
                  if (typeof(products[side.side_id]) != 'undefined') {
                      fat += products[side.side_id].fat / 1000;
                      protein += products[side.side_id].protein / 1000;
                      carb += products[side.side_id].carb / 1000;
                      calories += products[side.side_id].calories / 1000;
                    }
               });}
              }
            }

            sum_calories = sum_calories + calories;
            sum_protein = sum_protein + protein;
            sum_carbs = sum_carbs + carb;
            sum_fat = sum_fat + fat;
          }
          })

          if(idx == 0){
            bagCount = 2;
          }else{
            let splitVal = el.split("#");
            bagCount = splitVal[1];
          }
         return(
          <div className={`day ${xyz ? 'xyz-fin' : 'xyz'} ${'custom-day-delivery'} ${typeEdit ? 'editable-items' : ''} ${buffered ? 'in-buffer' : (inactive ? 'delivered' : '')}`} ref={el => (this.MainDayModRef = el)}>
        <div className='day-header'>
          <h5>Additional Delivery #{bagCount} <span>Total Calories: {sum_calories}, Fat: {sum_fat}g, Protein: {sum_protein}g, CHO: {sum_carbs}g </span></h5>
          <span className="additional-delivery-date">{date && date.format('dddd, Do MMM')}</span>
          {(!inactive && !(unix in skipped)) /* date.isBefore(originTo)) */ &&
          <div className='control-buttons'>
          </div>
          }
        </div>
        <nav className='top-params-filters w-max-content h-max-content px-0'>
          {this.renderTypeList(isdayactive,bagCount)}
          {typeEdit && Object.keys(activetypeList).length>0 && <Fragment> <div className='container-fluid mob_side'><div className='row'>
           <div className="Left col-12 col-lg-3 col-md-4 mb-12">
              <div className='products sidedishcon'><h3>Side Dishes</h3>{this.renderSidesProduct()}</div>
          </div>
           <div className="Right col-12 col-lg-9 col-md-8 mb-12">
           <h3>Main Dishes</h3>{this.renderCarousel(breakpoints,3,this.renderCustomProduct(el,isdayactive))}
              </div></div></div></Fragment>}
          {(!typeEdit ||  (typeEdit && Object.keys(activetypeList).length<=0)) && this.renderCarousel(breakpoints,4,this.renderCustomProduct(el,isdayactive))}
          {/* <Carousel
           arrowLeft={<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>}
           arrowRight={<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>}
          addArrowClickHandler
          draggable ={false}
          slidesPerPage={4}
          slidesPerScroll={1}
          slides={this.renderCustomProduct(el,isdayactive)}
          //slides={[]}
           breakpoints={{
              1400: {
                slidesPerPage: 4,
                slidesPerScroll:1
              },
              992: { // these props will be applied when screen width is less than 1000px
                slidesPerPage: 3,
                slidesPerScroll:1,
              },
              479: {
                slidesPerPage: 1,
                slidesPerScroll:1,
              },
          }}
          /> */}
         
        </nav>
      </div>
          )

      })
      )
    }

      {!custom &&
      <div className={`day ${xyz ? 'xyz-fin' : 'xyz'} ${custom ? 'custom-day-delivery' : ''} ${typeEdit ? 'editable-items' : ''} ${buffered ? 'in-buffer' : (inactive ? 'delivered' : '')}`} ref={el => (this.MainDayModRef = el)}>
        <div className='day-header'>
          <h5>{date && date.format('dddd, Do MMM')} <span>Total Calories: {sum_calories}, Fat: {sum_fat}g, Protein: {sum_protein}g, CHO: {sum_carbs}g </span></h5>
          {custom && <span className="additional-delivery-date">{date && date.format('dddd, Do MMM')}</span>}
          {(!inactive && !(unix in skipped)) /* date.isBefore(originTo)) */ &&
          <div className='control-buttons'>
          {!custom &&
            <ToolTip
              overlayClassName={'on-skip'}
              tooltipDirection={'bottomRight'}
              trigger={['click']}
              tooltipText={<div>
                {!isSkipped &&
                  <Fragment>
                    <div className='regular-bold'>Click to skip this delivery.</div>
                    <div className='t-total'><span className='green'>New delivery will appear at the end of the plan.</span></div>
                    <div className='regular-bold'>Meals for this day will be selected based on the recommendations of a nutritionist.</div>
                    <div className='t-total'><span className='green'>You can change the selection and select the meals you need.</span></div>
                  </Fragment>
                }
                {isSkipped &&
                  <Fragment>
                    <div className='t-total'><span className='green'>Click to resume delivery that day.</span></div>
                  </Fragment>
                }
              </div>}
            >
              <span className='skips'>?</span>
            </ToolTip> }
            {!custom && <span
              className={isSkipped ? 'button button-skipped' : 'button'}
              onClick={() => this.confirmSkipModal(unix)} //skipTheDay(unix)}//changeSkipped(unix)}
            >
              {isSkipped ? 'Remove from skipped' : 'Skip delivery'}
            </span>}
          </div>
          }
        </div>
        <nav className='top-params-filters w-max-content h-max-content px-0'>
          {this.renderTypeList(isdayactive,'InitialBag')}
          {typeEdit && Object.keys(activetypeList).length>0 && <Fragment>  <div className='container-fluid mob_side'><div className='row'>
           <div className="Left col-12 col-lg-3 col-md-4 mb-12">
              <div className='products sidedishcon'><h3>Side Dishes</h3>{this.renderSidesProduct()}</div>
          </div>
           <div className="Right col-12 col-lg-9 col-md-8 mb-12">
           <h3>Main Dishes</h3>{this.renderCarousel(breakpoints,3,this.renderProduct(isdayactive))}
          </div></div></div></Fragment>}
          {(!typeEdit ||  (typeEdit && Object.keys(activetypeList).length<=0)) && this.renderCarousel(breakpoints,4,this.renderProduct(isdayactive))}
          {/* <Carousel
           arrowLeft={<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>}
           arrowRight={<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>}
          addArrowClickHandler
          slidesPerPage={4}
          slidesPerScroll={1}
          draggable ={false}
          slides={this.renderProduct(isdayactive)}
           breakpoints={{
              1400: {
                slidesPerPage: 4,
                slidesPerScroll:1
              },
              992: { // these props will be applied when screen width is less than 1000px
                slidesPerPage: 3,
                slidesPerScroll:1,
              },
              479: {
                slidesPerPage: 1,
                slidesPerScroll:1,
              },
          }}
          /> */}
         
        </nav>
      </div>
        }
        </div>
    );
  }
}

Day.propTypes = {
  dataArray: PropTypes.object.isRequired,
  socket: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  customPlans: PropTypes.array.isRequired,
  customPlanSides: PropTypes.array.isRequired,
  originTo: PropTypes.object.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  setCustomMealPlan: PropTypes.func.isRequired,
  setCustomPlanSides: PropTypes.func.isRequired,
  setCustomPlanParams: PropTypes.func.isRequired,
  changeSkipped: PropTypes.func.isRequired,
  skipped: PropTypes.object.isRequired,
  date: PropTypes.object,
  inactive: PropTypes.bool,
  user: PropTypes.object.isRequired,
  goMenu: PropTypes.func
};

Day.defaultProps = {
  inactive: false
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  mealPlan: state.mealPlan,
  user: state.user,
  planParams: state.planParams,
  customPlans: state.customPlans,
  customPlanSides: state.customPlanSides,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
});

const bindAction = dispatch => ({
  setMealPlan: plan => dispatch(setMealPlan(plan)),
  setPlanParams: params => dispatch(setPlanParams(params)),
  setCustomMealPlan: plan => dispatch(setCustomMealPlan(plan)),
  setCustomPlanSides: plan => dispatch(setCustomPlanSides(plan)),
  setCustomPlanParams: params => dispatch(setCustomPlanParams(params)),
  setModal: data => dispatch(setModal(data)),
  clearModal: data => dispatch(clearModal(data)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop())
});

export default socketConnect(connect(mapStateToProps, bindAction)(Day));
