import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import InputValidation from 'components/InputValidation';
import TextAriaValidation from 'components/TextAriaValidation';
import InputCalendar from 'components/InputCalendar';
import SelectValidation from 'components/SelectValidation';
 
class ProfileForm extends Component {
  render() { 
    const { locale, listGender, listLanguage, errors, onChange, userData,areasList,allEmirates,ingrediantsList, productFullList } = this.props;
    const { firstName, lastName, phone, gender, weight, height, birthday, medicalCondition, email, language, homeAddress, publicAddress, workAddress,allergies, dislikes, dishlikes, dishdislikes } = userData;
    
    var allergiesData = [];
    var allergiesdislikesData = [];
    var dishlikesData = [];
    var dishdislikesData = [];

    allergies.forEach((alk)=>{      
      allergiesData.push(ingrediantsList[alk]);
    });
    dislikes.forEach((alkn)=>{
      allergiesdislikesData.push(ingrediantsList[alkn]);    
    });
    dishlikes.forEach((dl)=>{
      console.log(productFullList[dl]['title']);
      dishlikesData.push(productFullList[dl]['title']);    
    });
    dishdislikes.forEach((dln)=>{
      dishdislikesData.push(productFullList[dln]['title']);    
    });
    
    return (
      <div className='container-fluid regular-page position-relative'>
        <div className='row first-mt-2 form-line'>
          <div className='col-12 col-md-4 p-0'>
            <h5 className='green'>* 1. First Name</h5>
            <div className='bg-gr-light-2 readonly_field'>
              <InputValidation
                name={'firstName'}
                value={firstName || ''}
                placeholder='First Name'
                onChange={onChange}
                errors={errors}
              />
            </div>
          </div>
          <div className='col-12 col-md-4 p-0'>
            <h5 className='green'>* 2. Last Name</h5>
            <div className='bg-gr-light-2 readonly_field'>
              <InputValidation
                name={'lastName'}
                value={lastName || ''}
                placeholder='Last Name'
                onChange={onChange}
                errors={errors}
              />
            </div>
          </div>
          <div className='col-12 col-md-4 p-0'>
            <h5 className='green'>* 3. Mobile Number</h5>
            <div className='bg-gr-light-2 readonly_field'>
              <InputValidation
                name={'phone'}
                value={phone}
                mask='tel'
                maskProps={{
                  mask: '+\\9\\7\\1(99)999 99 99 99',
                  permanents: [0, 1, 2, 3, 4, 7, 11, 14, 17],
                  alwaysShowMask: false,
                  maskChar: ' '
                }}
                placeholder='Mobile Number'
                onChange={onChange}
                errors={errors}
              />
            </div>
          </div>
        </div>
        <div className='row mt-3 form-line'>
          <div className='col-12 col-md-6 col-lg-3 p-0 d-flex flex-column justify-content-between'>
            <h5 className='green'>4. Weight</h5>
            <div className='letter-xs mb-3 light-dark-text'>Please fill in your actual Weight in kg</div>
            <div className='bg-gr-light-2'>
              <InputValidation
                type={'number'}
                min={0}
                max={500}
                step={'0.001'}
                name={'weight'}
                value={weight / 1000 || ''}
                placeholder='Weight'
                onChange={(name, value) => onChange(name, value * 1000)}
                errors={errors}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-3 p-0  d-flex flex-column justify-content-between'>
            <h5 className='green'>5. Height</h5>
            <div className='letter-xs mb-3 light-dark-text'>Please fill in your height in cm</div>
            <div className='bg-gr-light-2'>
              <InputValidation
                type={'number'}
                min={0}
                max={300}
                step={'0.01'}
                name={'height'}
                value={height || ''}
                placeholder='Height'
                onChange={onChange}
                errors={errors}
              />
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-2 p-0 d-flex flex-column justify-content-between'>
            <h5 className='green'>* 6. Birthday</h5>
            <div className='bg-gr-light-2 prof-cal-holder'>
              <InputCalendar
                value={birthday || ''}
                fullYear
                locale={locale}
                name='birthday'
                onChange={birthday => this.props.onChange('birthday', birthday)}
                errors={errors}
                disable={false}
                placeholder={'Birthday'}
                defaultValue={moment().subtract(18, 'y')}
              />
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-2 p-0 d-flex flex-column justify-content-between'>
            <h5 className='green'>7. Gender</h5>
            <div className='bg-gr-light-2'>
              <label className='g-toggle  b-shadowed-gray'>
                <input
                  type='checkbox'
                  onChange={e => this.props.onChange('gender', Object.keys(listGender)[+e.target.checked])}
                  checked={gender !== Object.keys(listGender)[0]}
                />
                <span className='g-nop'>Male</span>
                <span className='g-nop'>Female</span>
                <span className='g-mark' />
              </label>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-2 p-0 d-flex flex-column justify-content-between'>
            <h5 className='green'>8. Language Preference</h5>
            <div className='bg-gr-light-2'>
              <div className='b-shadowed-gray'>
                <SelectValidation
                  disabled = {'true'}
                  name={'language'}
                  value={language}
                  onChange={onChange}
                  errors={errors}
                  list={listLanguage}
                  emptyValue={'Language'}
                  canEmpty
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3 form-line'>
          <div className='col-12 p-0'>
            <h5 className='green'>9. Medical condition</h5>
            <div className='letter-xs mb-3 light-dark-text'>Please fill in information about your medical conditions and medication currently taking</div>
            <div className='bg-gray-bordered padded'>
              <TextAriaValidation
                name={'medicalCondition'}
                value={medicalCondition || ''}
                errors={errors}
                onChange={onChange}
                placeholder='Please type here...'
                rows={3}
              />
            </div>
          </div>
        </div>
        <div className='row mt-3 form-line'>
          <div className='col-12 p-0 col-md-4 col-lg-3'>
            <h5 className='green'>* 10. Your e-mail</h5>
            <div className='letter-xs mb-3 light-dark-text'>You can change your email here</div>
            <div className='bg-gr-light-2 padded readonly_field'>
              <InputValidation
                name={'email'}
                value={email || ''}
                placeholder='Email'
                onChange={onChange}
                errors={errors}
              />
            </div>
          </div>
        </div>
        <div className='row mt-3 form-line address_div'>
        {homeAddress!=null&&homeAddress!=''&&<div className='addr_sec'>
            <h1>Main Address</h1>
            <div className='adddiv'>
              <label>{homeAddress['street']}, {areasList[homeAddress['area']]}, {allEmirates[homeAddress['emirate']]}, {homeAddress['apartments']}, {homeAddress['landmark']},<br/> {homeAddress['locationlink']}</label>
            </div>
          </div>}
          {workAddress!=null&&workAddress!=''&&<div className='addr_sec'>
            <h1>2<sub>nd</sub> Address</h1>
            <div className='adddiv'>
            <label>{workAddress['street']}, {areasList[workAddress['area']]}, {allEmirates[workAddress['emirate']]}, {workAddress['apartments']}, {workAddress['landmark']}, <br/>{workAddress['locationlink']}</label>
            </div>
          </div>}
          {publicAddress!=null&&publicAddress!=''&&<div className='addr_sec'>
            <h1>3<sub>rd</sub> Address</h1>
            <div className='adddiv'>
            <label>{publicAddress['street']}, {areasList[publicAddress['area']]}, {allEmirates[publicAddress['emirate']]}, {publicAddress['apartments']}, {publicAddress['landmark']},<br/> {publicAddress['locationlink']}</label>
            </div>
          </div>}
          </div>
        <div className='row mt-3 form-line address_div fpdiv'>
        <div className='col-12 col-md-12 col-lg-12 p-0'><h1>Food Preferences</h1></div>
          <div className='dishcont'>
            <div className="dislist"><h2>Allergies :</h2>
            {allergiesData.join(" , ")}
            </div>
          </div>
          <div className='dishcont'>
            <div className="dislist"><h2>Dislikes :</h2>
            {allergiesdislikesData.join(" , ")}
            </div>
          </div>
          <div className='dishcont'>
            <div className="dislist"><h2>Favorite Dishes :</h2>
            {dishlikesData.join(" , ")}
            </div>
          </div>
          <div className='dishcont'>
            <div className="dislist"><h2>Disliked Dishes :</h2>
              {dishdislikesData.join(" , ")}
              </div>
          </div>
        </div>
        <div className='row mb-md-4 mt-md-3'>
          <div className='col-12 col-md-6 col-sm-7 col-md-8 col-lg-9 p-0'>
            <span className='letter-xs light-dark-text mt-3 mb-0'>Your contact information will be used for logistics purposes.</span>
          </div>
        </div>
      </div>
    );
  }
}

ProfileForm.propTypes = {
  userData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  listGender: PropTypes.object.isRequired,
  listLanguage: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ProfileForm;
